import { Divider } from '@/components/base/divider';
import { SocialLoginButtons } from '@/components/base/social-buttons';
import { useAnalytics } from '@/context/analytics-context';
import { CredentialsContext } from '@/context/credentials-context';
import { useLoginUserMutation } from '@graphql/index';
import { Link } from '@tanstack/react-router';
import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FormLayout } from '../auth-form-layout';
import { EmailAuthButton } from '../email-auth-button';
import { LoginForm } from './login-form';

enum FormTypeEnum {
  SocialButtons = 'SocialButtons',
  SignIn = 'SignIn',
}

export function Login(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [loginMutation, { loading }] = useLoginUserMutation();
  const { t } = useTranslation();
  const [formType, setFormType] = useState<FormTypeEnum>(
    FormTypeEnum.SocialButtons,
  );
  const { setToken } = useContext(CredentialsContext);
  const { identify } = useAnalytics();
  // Form submit function
  async function submit(values: {
    email: string;
    password: string;
  }): Promise<void> {
    try {
      const { data } = await loginMutation({
        variables: { username: values.email, password: values.password },
      });
      if (data?.login?.token) {
        setToken(data.login.token);
        identify(data.login.id, {
          first_name: data.login.firstName,
          last_name: data.login.lastName,
          email: data.login.email,
        });
      }
    } catch (error) {
      enqueueSnackbar({
        message: t('login.invalidCredentials'),
        variant: 'error',
      });
    }
  }

  function openLoginForm(): void {
    setFormType(FormTypeEnum.SignIn);
  }
  function closeLoginForm(): void {
    setFormType(FormTypeEnum.SocialButtons);
  }

  return (
    <div className="flex h-full w-full justify-center overflow-y-scroll rounded-none bg-default-gray-950 p-5 text-white shadow-xl sm:p-10 lg:rounded-tl-[20px]">
      <div className="relative mx-auto h-full w-full">
        <FormLayout
          link={
            <div className="text-base">
              {/* link */}
              <Trans
                i18nKey="login.noAccount"
                components={{
                  sign: (
                    <Link
                      to="/auth/registration"
                      className="text-lg font-medium underline underline-offset-4"
                    />
                  ),
                }}
              />
            </div>
          }
          subtitleText={
            formType === FormTypeEnum.SignIn
              ? t('login.signInWithEmail')
              : t('login.signIn')
          }
          arrowBackText={t('login.signIn')}
          showGoBackOption={formType === FormTypeEnum.SignIn}
          onClickBack={closeLoginForm}
        >
          {formType === FormTypeEnum.SignIn && (
            <LoginForm onSubmit={submit} loading={loading} />
          )}
          {formType === FormTypeEnum.SocialButtons && (
            <div>
              <SocialLoginButtons
                googleButtonText={t('login.googleButtonText')}
              >
                <Divider>{t('login.orSignInWithEmail')}</Divider>
                <EmailAuthButton
                  data-testid="email-auth-button"
                  onClick={openLoginForm}
                />
              </SocialLoginButtons>
            </div>
          )}
        </FormLayout>
      </div>
    </div>
  );
}
