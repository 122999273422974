import { PageHeading } from '@/components/base/page-heading';
import Section from '@/components/base/section';
import { Skeleton } from '@/components/base/skeleton';
import SvgRiseLogo from '@/components/icons/rise-logo';
import {
  useGetFundedAccountsQuery,
  useRequestBrokerWithdrawalMutation,
  type FundedAccountFragment,
} from '@graphql/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import invariant from 'tiny-invariant';
import { z } from 'zod';
import { ChooseAmount } from './choose-amount';
import { CompleteRequest } from './complete-request';
import { SelectAccount } from './select-account';
import { SuccessfulRequest } from './success';
import { Summary } from './summary';

function ErrorMessage({ message }: { message: string }) {
  let component = <>{message}</>;

  if (message === 'CONSISTENCY_NEEDED') {
    component = (
      <Trans
        i18nKey="payout.consistencyRules"
        components={{
          faq: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content -- filled in lang
            <a
              rel="noopener"
              className="font-bold underline"
              href="https://help.monevis.com/en/articles/9961165-gambling-behaviour-on-funded-accounts"
              target="_blank"
            />
          ),
        }}
      />
    );
  }
  return (
    <div className="rounded-lg border border-red-700 bg-red-800/10 p-4 text-sm">
      {component}
    </div>
  );
}
export function RequestPayout({
  fundedAccounts,
  accountLogin,
}: {
  fundedAccounts: FundedAccountFragment[];
  accountLogin?: string;
}): JSX.Element {
  function getSelectedAccount(
    login: string,
  ): FundedAccountFragment | undefined {
    return fundedAccounts.find((item) => item.login === login);
  }

  const intl = useIntl();
  const currency = 'USD';
  const [selectedAccount, setSelectedAccount] = useState<string>(
    accountLogin ?? '',
  );

  const [currentAccount, setCurrentAccount] = useState<FundedAccountFragment>();
  const [requestWithdrawal] = useRequestBrokerWithdrawalMutation();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setCurrentAccount(getSelectedAccount(accountLogin ?? ''));
  }, [accountLogin]);

  function handleChangeAccount(login: string): void {
    setSelectedAccount(login);
    setCurrentAccount(getSelectedAccount(login));
  }

  const payoutSchema = z.object({
    amount: z
      .number()
      .min(1)
      .max(currentAccount?.withdrawable ?? 0, {
        message: t('requestPayoutPage.maxAmountError'),
      }),
  });

  type Payout = z.infer<typeof payoutSchema>;
  const methods = useForm<Payout>({
    resolver: zodResolver(payoutSchema),
    mode: 'onChange',
    values: {
      amount: currentAccount?.withdrawable ?? 0,
    },
  });
  const { setValue, watch, handleSubmit } = methods;
  const watchAmount = watch('amount');
  const avaibleAmount = !watchAmount ? 0 : watchAmount;

  useEffect(() => {
    setValue('amount', 0);
  }, [fundedAccounts, setValue]);

  function eligible(): boolean {
    if (currentAccount) {
      return (
        (currentAccount.eligibleForWithdrawal ?? false) &&
        Number(currentAccount.withdrawable) > 0
      );
    }
    return false;
  }

  async function withdraw(): Promise<void> {
    setErrorMessage('');
    try {
      await requestWithdrawal({
        variables: {
          amount: Number(watchAmount),
          id: currentAccount?.id ?? '',
        },
        refetchQueries: ['GetFundedAccounts'],
      });
      setShowSuccessDialog(true);
    } catch (e) {
      // @ts-expect-error -- has error
      // eslint-disable-next-line -- has error
      setErrorMessage(e?.message ?? '');
      enqueueSnackbar({
        message: 'Something went wrong',
        variant: 'error',
      });
    }
  }

  return (
    <Section
      variant="secondary"
      className="mx-auto max-w-xl text-default-gray-950 dark:text-white"
    >
      <div className="flex w-full flex-col gap-6">
        <PageHeading subheading={t('payout.newSubheading')}>
          {t('payout.requestNewButton')}
        </PageHeading>

        <SelectAccount
          data={fundedAccounts}
          selectedAccount={selectedAccount}
          onChange={handleChangeAccount}
          eligibleForWithdrawal={eligible()}
          nextEligiblePayout={currentAccount?.nextEligiblePayout ?? ''}
        />

        <ChooseAmount
          onChange={(value) => {
            setValue('amount', value);
          }}
          amount={watchAmount}
          eligible={!eligible()}
          withdrawable={currentAccount?.withdrawable ?? 0}
          error={methods.formState.errors.amount?.message}
        />

        <div className="flex w-full justify-between">
          <PageHeading subheading={t('payout.withdrawOptions')} />
          <SvgRiseLogo />
        </div>

        <Summary
          profitSplit={getSelectedAccount(selectedAccount)?.profitSplit}
          withdrawable={getSelectedAccount(selectedAccount)?.withdrawable ?? 0}
        />

        <PageHeading>{t('payout.availableAmount')}</PageHeading>
        <div
          data-testid="available-amount"
          className="flex items-start justify-center"
        >
          <span className="text-center text-4xl font-bold sm:text-6xl">
            {intl.formatNumber(avaibleAmount, {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
          <span>{currency}</span>
        </div>

        <div className="flex w-full flex-col text-center text-sm">
          <span>{t('payout.bankTransferNote')}</span>
          <span>{t('payout.processingTimeNote')}</span>
        </div>

        {errorMessage && <ErrorMessage message={errorMessage} />}

        <CompleteRequest
          id={currentAccount?.id}
          amount={watchAmount}
          accountName={currentAccount?.accountName}
          disabled={!eligible() || watchAmount < 1}
          onSubmit={() => {
            handleSubmit(withdraw)();
          }}
        />
        <SuccessfulRequest
          onClose={() => {
            setShowSuccessDialog(false);
          }}
          open={showSuccessDialog}
          amount={watchAmount}
        />
      </div>
    </Section>
  );
}

export function RequestPayoutWithData({
  accountLogin,
}: {
  accountLogin?: string;
}): JSX.Element {
  const { data, loading } = useGetFundedAccountsQuery();
  if (loading) {
    return (
      <div className="mx-auto h-full w-fit">
        <Skeleton className="h-full w-full min-w-[300px] rounded-xl sm:min-w-[600px]" />
      </div>
    );
  }

  invariant(data?.fundedAccounts, `Funded accounts missing`);

  return (
    <RequestPayout
      accountLogin={accountLogin}
      fundedAccounts={data?.fundedAccounts}
    />
  );
}
