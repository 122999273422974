import { Button } from '@/components/base/button';
//import { FormCheckbox } from '@/components/base/form/form-checkbox';
import { FormField } from '@/components/base/form/form-field';
import { Input } from '@/components/base/form/input';
import { NavLink } from '@/components/base/nav-link';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link } from '@tanstack/react-router';
import { type FormEvent, type ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const userSchema = z.object({
  email: z.string().min(1, { message: 'Email is required' }).email(),
  password: z.string().min(1, { message: 'Password is required' }),
  //remember_me: z.boolean().optional(),
});

type User = z.infer<typeof userSchema>;

export function LoginForm({
  onSubmit,
  loading,
}: {
  onSubmit: (values: User) => Promise<void>;
  loading: boolean;
  children?: ReactNode;
}): JSX.Element {
  // Variables setup
  const methods = useForm<User>({
    resolver: zodResolver(userSchema),
    mode: 'onChange',
  });
  const { handleSubmit } = methods;
  const { t } = useTranslation();
  // Handle form submit
  async function handleClickSubmit(
    e: FormEvent<HTMLFormElement>,
  ): Promise<void> {
    e.preventDefault();
    await handleSubmit(onSubmit)();
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => {
          handleClickSubmit(e);
        }}
        data-testid="login-form"
      >
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-5">
            {/* Email field */}
            <fieldset>
              <FormField label={t('login.email')} name="email">
                <Input
                  data-testid="email-input"
                  placeholder={t('login.emailPlaceholder')}
                />
              </FormField>
            </fieldset>
            {/* Password field */}
            <fieldset>
              <FormField label={t('login.password')} name="password">
                <Input
                  data-testid="password-input"
                  type="password"
                  placeholder="••••••••"
                />
              </FormField>
              <div className="flex w-full justify-end">
                <NavLink asChild>
                  <Link
                    to="/auth/forgotten-password"
                    className="text-sm font-bold text-rose-700"
                  >
                    {t('login.forgotPassword')}
                  </Link>
                </NavLink>
              </div>
            </fieldset>
          </div>

          {/*<div className="flex flex-row items-center gap-1">
            <div className="w-full">
              <FormCheckbox name="remember_me" id="remember_me">
                Remember Me
              </FormCheckbox>
            </div>
          </div>{/}}

          {/* Submit button */}
          <Button
            as="button"
            loading={loading}
            className="text-base font-bold"
            type="submit"
            data-testid="login-submit"
          >
            {t('login.submit')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
