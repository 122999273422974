import { Button } from '@/components/base/button';
import { PageHeading } from '@/components/base/page-heading';
import { Timebomb } from '@/components/icons';
import { useCheckBrokerAccountRestartQuery } from '@graphql/index';
import { Link } from '@tanstack/react-router';
import { Trans, useTranslation } from 'react-i18next';
import { RestartCountdown } from './restart-countdown';

export function BrokerAccountRestartPanel({
  accountId,
}: {
  accountId: string;
}) {
  const { t } = useTranslation();
  const { data, loading } = useCheckBrokerAccountRestartQuery({
    variables: {
      login: accountId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading || !data?.checkAccountRestart.eligible) {
    return <></>;
  }

  const percent = (1 - (data?.checkAccountRestart.percent ?? 0.75)) * 100;

  return (
    <div className="rounded-lg bg-gradient-to-r from-[#650017] to-[#CB002D] p-6 text-white">
      <div className="flex flex-col space-y-6 md:flex-row md:space-x-6 md:space-y-0">
        <div className="flex justify-center md:justify-start">
          <Timebomb />
        </div>
        <div className="flex-1">
          <PageHeading
            className="text-center md:text-left"
            subheading={
              <Trans
                i18nKey="BrokerAccountRestartPanel.subheading"
                components={{
                  time: (
                    <RestartCountdown
                      time={data?.checkAccountRestart.expiryTime}
                    />
                  ),
                }}
              />
            }
          >
            {t('BrokerAccountRestartPanel.heading')}
          </PageHeading>
        </div>
        <div className="flex justify-center md:justify-start">
          <Link
            to="/dashboard/accounts/restart/$account-id"
            params={{ 'account-id': accountId }}
          >
            <Button variant="gray">
              {t('BrokerAccountRestartPanel.restartButton', {
                perc: Number(percent).toFixed(0),
              })}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
