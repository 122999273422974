import { Button } from '@/components/base/button';
import { CopyTool } from '@/components/base/copytool';
import { Divider } from '@/components/base/divider';
import { Drawer } from '@/components/base/drawer';
import { PageHeading } from '@/components/base/page-heading';
import { Skeleton } from '@/components/base/skeleton';
import MetatraderDownload from '@/components/metatrader-download';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useGetBrokerAccountQuery,
  type BrokerAccountFragment,
} from '@graphql/index';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';

export function CredentialsModal({
  data,
}: {
  data: BrokerAccountFragment;
}): JSX.Element {
  const { login, server, masterPassword, investorPassword } = data;
  const { t } = useTranslation();

  return (
    <Drawer
      trigger={
        <Button
          icon={<FontAwesomeIcon icon={faLock} />}
          className="h-fit flex-1 font-bold"
          variant="gray"
        >
          {t('credentialsModal.password')}
        </Button>
      }
      contentClassName="h-[80%]"
    >
      <div className="flex h-full flex-col justify-between overflow-y-auto">
        <div className="flex flex-col items-start justify-center gap-4 text-2xl">
          <PageHeading size="xxlarge">
            {t('credentialsModal.title')}
          </PageHeading>
          <CopyTool label={t('credentialsModal.serverName')} text={server} />
          <CopyTool label={t('credentialsModal.login')} text={login} />
          <CopyTool
            hidden
            label={t('credentialsModal.password')}
            text={masterPassword}
          />
          <CopyTool
            label={t('accountDetail.investorPassword')}
            text={investorPassword}
            hidden
          />
        </div>

        <Divider className="bg-default-gray-800" />

        <MetatraderDownload variant="secondary" className="mt-5" />
      </div>
    </Drawer>
  );
}

export function CredentialsModalWithData({
  accountId,
}: {
  accountId: string;
}): JSX.Element {
  const { data, loading } = useGetBrokerAccountQuery({
    variables: { id: accountId },
  });

  if (loading) {
    return (
      <div className="flex flex-wrap gap-2">
        <Skeleton className="h-full min-h-8 w-full min-w-24" />
      </div>
    );
  }

  invariant(
    data?.brokerAccount,
    `[${accountId}] Broker account missing for credentials`,
  );

  return <CredentialsModal data={data?.brokerAccount} />;
}
