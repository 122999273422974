import { Badge } from '@/components/base/badge';
import { StepperProgressBar } from '@/components/base/progress-bar/stepper-progress-bar';
import Section from '@/components/base/section';
import { iconVariantMap } from '@/utils/challenge-config';
import {
  MonevisAnalysisResultOutcome,
  type MonevisAnalysisRuleFragment,
} from '@graphql/index';
import { useTranslation } from 'react-i18next';

export const colorMap: Record<string, { strokeColor: string }> = {
  [MonevisAnalysisResultOutcome.Evaluating]: { strokeColor: 'bg-[#236df9]' },
  [MonevisAnalysisResultOutcome.Pass]: { strokeColor: 'bg-[#2fe0a0]' },
  [MonevisAnalysisResultOutcome.Fail]: { strokeColor: 'bg-[#CB002D]' },
};

export function ProfitableDaysCard({
  analysis,
}: {
  analysis: MonevisAnalysisRuleFragment;
  accountId: string;
  outcome: MonevisAnalysisResultOutcome;
}): JSX.Element {
  const { t } = useTranslation();
  const { badgeVariant, badgeIcon } = iconVariantMap[analysis.result];
  const { strokeColor } = colorMap[analysis.result];

  return (
    <Section variant="secondary">
      <div
        className="flex w-full flex-col gap-3"
        data-testid={analysis.identifier}
      >
        <div className="flex flex-col items-center gap-6 sm:flex-row">
          <div className="flex w-full items-center justify-between gap-3 sm:justify-start">
            <div className="flex w-fit items-center gap-2">
              <Badge
                className="px-1 py-1"
                variant={badgeVariant}
                iconRight={badgeIcon}
                iconRightClassName="ml-0"
              />
              <span className="text-sm">
                {t('challengeCard.profitableDays')}
              </span>
            </div>
            <span className="w-fit text-lg font-semibold">
              {analysis.currentRelativeValue}/{analysis.targetRelativeValue}
            </span>
          </div>
        </div>
        <StepperProgressBar
          currentValue={analysis.currentRelativeValue}
          targetValue={analysis.targetRelativeValue}
          className={strokeColor}
        />
      </div>
    </Section>
  );
}
