import { AuthLayout } from '@/components/(unathenticated)/layout/auth.layout';
import { Registration } from '@/components/(unathenticated)/registration/registration';
import { SideDescriptionRegister } from '@/components/(unathenticated)/registration/side-description';
import { PageWrapper } from '@/components/base/page-wrapper';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/(unathenticated)/auth/_layout/registration',
)({
  component: () => (
    <PageWrapper title="Register - Monevis">
      <RegistrationPage />
    </PageWrapper>
  ),
});

function RegistrationPage(): JSX.Element {
  return (
    <AuthLayout
      sideContent={
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-black/55 px-20">
          <SideDescriptionRegister />
        </div>
      }
    >
      <Registration />
    </AuthLayout>
  );
}
