import { Czechia, Slovakia, UnitedKingdom } from '@/components/icons/index';
import { EventName, useAnalytics } from '@/context/analytics-context';
import { cn } from '@/utils';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher(): JSX.Element {
  const { i18n } = useTranslation();
  const { track } = useAnalytics();

  const handleLanguageChange = (lng: string): void => {
    i18n.changeLanguage(lng);
    track(EventName.ChangeLocale, {
      locale: lng,
    });
  };

  return (
    <div className="flex items-center space-x-4">
      <button
        type="button"
        className="rounded-md"
        onClick={() => {
          handleLanguageChange('en');
        }}
        tabIndex={0}
        aria-label="English"
      >
        <UnitedKingdom
          className={cn('h-8', { 'border-b': i18n.language === 'en' })}
          aria-hidden="true"
        />
      </button>
      <button
        type="button"
        className="rounded-md"
        onClick={() => {
          handleLanguageChange('cs');
        }}
        tabIndex={0}
        aria-label="Čeština"
      >
        <Czechia
          className={cn('h-8', { 'border-b': i18n.language === 'cs' })}
          aria-hidden="true"
        />
      </button>
      <button
        type="button"
        className="rounded-md"
        onClick={() => {
          handleLanguageChange('sk');
        }}
        tabIndex={0}
        aria-label="Slovenčina"
      >
        <Slovakia
          className={cn('h-8', { 'border-b': i18n.language === 'sk' })}
          aria-hidden="true"
        />
      </button>
    </div>
  );
}

export default LanguageSwitcher;
