import { useMediaQuery } from 'usehooks-ts';

export function useScreenSize(): {
  isLarge: boolean;
  isMedium: boolean;
  isSmall: boolean;
} {
  const screenSizes = {
    lg: '(min-width: 1024px)',
    md: '(min-width: 768px)',
    sm: '(min-width: 640px)',
  };

  const isLarge = useMediaQuery(screenSizes.lg);
  const isMedium = useMediaQuery(screenSizes.md);
  const isSmall = useMediaQuery(screenSizes.sm);

  return { isLarge, isMedium, isSmall };
}
