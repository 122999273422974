import { Button } from '@/components/base/button';
import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import WidgetWithImage from '@/components/base/widget-with-image';
import { usePageLayout } from '@/context/layout-context';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/affiliate',
)({
  component: () => (
    <PageWrapper title="Certificates - Monevis">
      <Affiliate />
    </PageWrapper>
  ),
});

function Affiliate(): JSX.Element {
  const { t } = useTranslation();

  usePageLayout({ title: t('affiliatePage.title') });

  return (
    <ScreenContainer className="justify-between">
      <div className="my-8">
        <WidgetWithImage
          img="/images/affiliate-certificates.png"
          header={t('affiliatePage.header')}
          subheader={t('affiliatePage.subheader')}
        >
          <Button
            as="link"
            className="dark:text-white"
            href="https://affiliates.monevis.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('affiliatePage.joinProgram')}
          </Button>
          <Button
            variant="outlined"
            as="link"
            className="dark:text-white"
            href="https://certificates.monevis.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('affiliatePage.showCertificates')}
          </Button>
          <Button
            as="link"
            variant="link"
            className="underline dark:text-white"
            href="https://www.monevis.com/partners"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('affiliatePage.readMore')}
          </Button>
        </WidgetWithImage>
      </div>
    </ScreenContainer>
  );
}

export default Affiliate;
