/* eslint-disable -- no need */
import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
export type AccountTradingPositionFragment = {
  __typename?: 'AccountTradingPosition';
  id?: string | null;
  login?: number | null;
  symbol?: string | null;
  profit?: number | null;
  totalProfit?: number | null;
  fees?: number | null;
  dateOpen?: string | null;
  dateClose?: string | null;
  priceOpen?: number | null;
  priceClose?: number | null;
  duration?: number | null;
  lots?: number | null;
  action?: AccountTradingPositionAction | null;
  user?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    token?: string | null;
    emailVerifiedAt?: string | null;
    phoneVerifiedAt?: string | null;
    phone?: string | null;
    affiliateUsername: string;
    preferredLocale: string;
    countryCode?: string | null;
    role?: string | null;
    createdAt?: string | null;
    kycStatus?: KycStatus | null;
  } | null;
};

export type AddonFragment = {
  __typename?: 'Addon';
  name: string;
  type: AddonType;
  percentageIncrease: number;
};

export type BrokerAccountStatisticsFragment = {
  __typename?: 'BrokerAccountStatistics';
  id: string;
  equity: number;
  balance: number;
  floatingPnL: number;
  updatedUtc: string;
  restarted?: boolean | null;
  stats?: {
    __typename?: 'MonevisAnalysis';
    login: number;
    outcome: MonevisAnalysisResultOutcome;
    autoLiquidationValue: number;
    analysis?: Array<{
      __typename?: 'MonevisAnalysisRule';
      result: MonevisAnalysisResultOutcome;
      identifier: string;
      absoluteValueType: string;
      currentAbsoluteValue: number;
      targetAbsoluteValue: number;
      percentageValueType: string;
      currentPercentageValue: number;
      targetPercentageValue: number;
      relativeValueType: string;
      currentRelativeValue: number;
      targetRelativeValue: number;
      ruleType: string;
    } | null> | null;
  } | null;
};

export type BrokerAccountTransactionFragment = {
  __typename?: 'BrokerAccountTransaction';
  id: string;
  userId: string;
  amount: number;
  type: string;
  createdAt: string;
  status: string;
  releasedAt?: string | null;
  comment?: string | null;
  accountId?: string | null;
  profitSplit: number;
  profitSplitAmount: number;
  performanceAmount: number;
};

export type BrokerAccountFragment = {
  __typename?: 'BrokerAccount';
  id: string;
  initialBalance: number;
  currentBalance: number;
  currentEquity: number;
  currentPnl: number;
  lastUpdatedAt?: string | null;
  login: string;
  server: string;
  masterPassword: string;
  investorPassword: string;
  accountName?: string | null;
  accountType: AccountType;
  leverage: number;
  createdAt: string;
  challengeStatus: ChallengeStatus;
  failedAt?: string | null;
  payoutPeriod?: number | null;
  profitSplit?: number | null;
};

export type CalendarRecordFragment = {
  __typename?: 'CalendarRecord';
  id?: string | null;
  title?: string | null;
  country?: string | null;
  date?: string | null;
  impact?: string | null;
  forecast?: string | null;
  previous?: string | null;
  url?: string | null;
};

export type CartFragment = {
  __typename?: 'Cart';
  id: string;
  total: number;
  subTotal: number;
  discounts: number;
  accountType?: AccountType | null;
  initialBalance?: number | null;
  appliedDiscount?: string | null;
  discountType?: string | null;
  status?: CartStatus | null;
  addons?: Array<{
    __typename?: 'Addon';
    name: string;
    type: AddonType;
    percentageIncrease: number;
  } | null> | null;
};

export type CertificateFragment = {
  __typename?: 'Certificate';
  id: string;
  ulidx: string;
  firstName: string;
  lastName: string;
  email: string;
  amount?: number | null;
  type: CertificateType;
  publicUrl: string;
  privateUrl: string;
  createdAt: string;
  user?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    token?: string | null;
    emailVerifiedAt?: string | null;
    phoneVerifiedAt?: string | null;
    phone?: string | null;
    affiliateUsername: string;
    preferredLocale: string;
    countryCode?: string | null;
    role?: string | null;
    createdAt?: string | null;
    kycStatus?: KycStatus | null;
  } | null;
  metadata: {
    __typename?: 'CertificateMetadata';
    amount?: number | null;
    privateKey?: string | null;
  };
};

export type FundedAccountFragment = {
  __typename?: 'FundedAccount';
  id: string;
  accountName: string;
  initialBalance: number;
  login: string;
  currentBalance: number;
  openedTrades: number;
  deletedAt?: string | null;
  withdrawable: number;
  eligibleForWithdrawal: boolean;
  payoutFrequency: number;
  profitSplit: number;
  nextEligiblePayout?: string | null;
};

export type GeolocationResponseFragment = {
  __typename?: 'GeolocationResponse';
  country?: string | null;
  countryName?: string | null;
  city?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  zipCode?: string | null;
};

export type KycRequestFragment = {
  __typename?: 'KycRequest';
  status: string;
  id: string;
  url: string;
  createdAt: string;
};

export type MonevisAccountTradingDiaryFragment = {
  __typename?: 'MonevisAccountTradingDiary';
  id?: number | null;
  login?: string | null;
  year?: string | null;
  month?: string | null;
  day?: string | null;
  startingBalance?: number | null;
  startingEquity?: number | null;
  endingBalance?: number | null;
  tradesExecuted?: number | null;
  lotsExecuted?: number | null;
  endingEquity?: number | null;
};

export type MonevisAnalysisRuleFragment = {
  __typename?: 'MonevisAnalysisRule';
  result: MonevisAnalysisResultOutcome;
  identifier: string;
  absoluteValueType: string;
  currentAbsoluteValue: number;
  targetAbsoluteValue: number;
  percentageValueType: string;
  currentPercentageValue: number;
  targetPercentageValue: number;
  relativeValueType: string;
  currentRelativeValue: number;
  targetRelativeValue: number;
  ruleType: string;
};

export type MonevisAnalysisFragment = {
  __typename?: 'MonevisAnalysis';
  login: number;
  outcome: MonevisAnalysisResultOutcome;
  autoLiquidationValue: number;
  analysis?: Array<{
    __typename?: 'MonevisAnalysisRule';
    result: MonevisAnalysisResultOutcome;
    identifier: string;
    absoluteValueType: string;
    currentAbsoluteValue: number;
    targetAbsoluteValue: number;
    percentageValueType: string;
    currentPercentageValue: number;
    targetPercentageValue: number;
    relativeValueType: string;
    currentRelativeValue: number;
    targetRelativeValue: number;
    ruleType: string;
  } | null> | null;
};

export type OrderResponseFragment = {
  __typename?: 'OrderResponse';
  id: string;
  orderNumber: string;
  status: OrderStatus;
  total: number;
  subTotal: number;
  discounts: number;
  accountType: AccountType;
  initialBalance: number;
  token?: string | null;
  createdAt?: string | null;
  expiresAt?: string | null;
  currency?: string | null;
  leverage?: number | null;
  brokerAccount?: {
    __typename?: 'BrokerAccount';
    id: string;
    initialBalance: number;
    login: string;
    server: string;
    masterPassword: string;
    investorPassword: string;
    accountName?: string | null;
    leverage: number;
  } | null;
  user?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    token?: string | null;
  } | null;
  address?: {
    __typename?: 'OrderAddressPayload';
    countryCode: string;
    email?: string | null;
  } | null;
  addons?: Array<{
    __typename?: 'Addon';
    name: string;
    type: AddonType;
    percentageIncrease: number;
  } | null> | null;
};

export type OrderWithIntentFragment = {
  __typename?: 'OrderResponse';
  id: string;
  orderNumber: string;
  status: OrderStatus;
  total: number;
  subTotal: number;
  discounts: number;
  accountType: AccountType;
  initialBalance: number;
  token?: string | null;
  createdAt?: string | null;
  currency?: string | null;
  leverage?: number | null;
  brokerAccount?: {
    __typename?: 'BrokerAccount';
    id: string;
    initialBalance: number;
    login: string;
    server: string;
    masterPassword: string;
    investorPassword: string;
    accountName?: string | null;
    leverage: number;
  } | null;
  user?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    token?: string | null;
  } | null;
  address?: {
    __typename?: 'OrderAddressPayload';
    countryCode: string;
    email?: string | null;
  } | null;
  paymentIntent: {
    __typename?: 'Payment';
    id: string;
    status: string;
    gatewayUrl?: string | null;
    reference?: string | null;
    gateway?: PaymentMethod | null;
  };
  addons?: Array<{
    __typename?: 'Addon';
    name: string;
    type: AddonType;
    percentageIncrease: number;
  } | null> | null;
};

export type PaymentFragment = {
  __typename?: 'Payment';
  id: string;
  status: string;
  gatewayUrl?: string | null;
  reference?: string | null;
  gateway?: PaymentMethod | null;
};

export type TransactionFragment = {
  __typename?: 'Transaction';
  id: string;
  amount: number;
  type: string;
  createdAt: string;
  comment?: string | null;
  status?: string | null;
  transactionType: TransactionType;
  releasedAt?: string | null;
};

export type UserFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  token?: string | null;
  emailVerifiedAt?: string | null;
  phoneVerifiedAt?: string | null;
  phone?: string | null;
  affiliateUsername: string;
  preferredLocale: string;
  countryCode?: string | null;
  role?: string | null;
  createdAt?: string | null;
  kycStatus?: KycStatus | null;
};

export type WalletFragment = {
  __typename?: 'Wallet';
  balance: number;
  transactions: Array<{
    __typename?: 'Transaction';
    id: string;
    amount: number;
    type: string;
    createdAt: string;
    comment?: string | null;
    status?: string | null;
    transactionType: TransactionType;
    releasedAt?: string | null;
  }>;
};

export type ArchiveBrokerAccountMutationVariables = Exact<{
  login: Scalars['String']['input'];
}>;

export type ArchiveBrokerAccountMutation = {
  __typename?: 'Mutation';
  archiveBrokerAccount?: {
    __typename?: 'BrokerAccount';
    id: string;
    initialBalance: number;
    currentBalance: number;
    currentEquity: number;
    currentPnl: number;
    lastUpdatedAt?: string | null;
    login: string;
    server: string;
    masterPassword: string;
    investorPassword: string;
    accountName?: string | null;
    accountType: AccountType;
    leverage: number;
    createdAt: string;
    challengeStatus: ChallengeStatus;
    failedAt?: string | null;
    payoutPeriod?: number | null;
    profitSplit?: number | null;
  } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  payload: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  changePassword?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    token?: string | null;
    emailVerifiedAt?: string | null;
    phoneVerifiedAt?: string | null;
    phone?: string | null;
    affiliateUsername: string;
    preferredLocale: string;
    countryCode?: string | null;
    role?: string | null;
    createdAt?: string | null;
    kycStatus?: KycStatus | null;
  } | null;
};

export type ForgottenPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type ForgottenPasswordMutation = {
  __typename?: 'Mutation';
  forgottenPassword: {
    __typename?: 'ForgottenPasswordResponse';
    status: string;
  };
};

export type ImpersonateUserMutationVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type ImpersonateUserMutation = {
  __typename?: 'Mutation';
  impersonate?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    token?: string | null;
    emailVerifiedAt?: string | null;
    phoneVerifiedAt?: string | null;
    phone?: string | null;
    affiliateUsername: string;
    preferredLocale: string;
    countryCode?: string | null;
    role?: string | null;
    createdAt?: string | null;
    kycStatus?: KycStatus | null;
  } | null;
};

export type LoginUserMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type LoginUserMutation = {
  __typename?: 'Mutation';
  login?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    token?: string | null;
    emailVerifiedAt?: string | null;
    phoneVerifiedAt?: string | null;
    phone?: string | null;
    affiliateUsername: string;
    preferredLocale: string;
    countryCode?: string | null;
    role?: string | null;
    createdAt?: string | null;
    kycStatus?: KycStatus | null;
  } | null;
};

export type PlaceOrderMutationVariables = Exact<{
  cartId: Scalars['String']['input'];
  address: OrderAddress;
  paymentMethod: PaymentMethod;
  registrationData?: InputMaybe<RegistrationInputForOrder>;
}>;

export type PlaceOrderMutation = {
  __typename?: 'Mutation';
  placeOrder: {
    __typename?: 'OrderResponse';
    id: string;
    orderNumber: string;
    status: OrderStatus;
    total: number;
    subTotal: number;
    discounts: number;
    accountType: AccountType;
    initialBalance: number;
    token?: string | null;
    createdAt?: string | null;
    expiresAt?: string | null;
    currency?: string | null;
    leverage?: number | null;
    brokerAccount?: {
      __typename?: 'BrokerAccount';
      id: string;
      initialBalance: number;
      login: string;
      server: string;
      masterPassword: string;
      investorPassword: string;
      accountName?: string | null;
      leverage: number;
    } | null;
    user?: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      token?: string | null;
    } | null;
    address?: {
      __typename?: 'OrderAddressPayload';
      countryCode: string;
      email?: string | null;
    } | null;
    addons?: Array<{
      __typename?: 'Addon';
      name: string;
      type: AddonType;
      percentageIncrease: number;
    } | null> | null;
  };
};

export type PlaceTrialOrderMutationVariables = Exact<{
  balance: Scalars['Float']['input'];
  address: OrderAddress;
  registrationData?: InputMaybe<RegistrationInput>;
}>;

export type PlaceTrialOrderMutation = {
  __typename?: 'Mutation';
  placeTrialOrder: {
    __typename?: 'TrialResponse';
    token?: string | null;
    user?: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      token?: string | null;
      emailVerifiedAt?: string | null;
      phoneVerifiedAt?: string | null;
      phone?: string | null;
      affiliateUsername: string;
      preferredLocale: string;
      countryCode?: string | null;
      role?: string | null;
      createdAt?: string | null;
      kycStatus?: KycStatus | null;
    } | null;
    brokerAccount?: {
      __typename?: 'BrokerAccount';
      id: string;
      initialBalance: number;
      currentBalance: number;
      currentEquity: number;
      currentPnl: number;
      lastUpdatedAt?: string | null;
      login: string;
      server: string;
      masterPassword: string;
      investorPassword: string;
      accountName?: string | null;
      accountType: AccountType;
      leverage: number;
      createdAt: string;
      challengeStatus: ChallengeStatus;
      failedAt?: string | null;
      payoutPeriod?: number | null;
      profitSplit?: number | null;
    } | null;
  };
};

export type RegisterUserMutationVariables = Exact<{
  user: RegistrationInput;
}>;

export type RegisterUserMutation = {
  __typename?: 'Mutation';
  register?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    token?: string | null;
    emailVerifiedAt?: string | null;
    phoneVerifiedAt?: string | null;
    phone?: string | null;
    affiliateUsername: string;
    preferredLocale: string;
    countryCode?: string | null;
    role?: string | null;
    createdAt?: string | null;
    kycStatus?: KycStatus | null;
  } | null;
};

export type RequestBrokerWithdrawalMutationVariables = Exact<{
  id: Scalars['String']['input'];
  amount: Scalars['Float']['input'];
}>;

export type RequestBrokerWithdrawalMutation = {
  __typename?: 'Mutation';
  withdrawFromBrokerAccount?: {
    __typename?: 'BrokerAccountTransaction';
    id: string;
  } | null;
};

export type RequestCartMutationVariables = Exact<{
  cartId?: InputMaybe<Scalars['String']['input']>;
}>;

export type RequestCartMutation = {
  __typename?: 'Mutation';
  requestCart: {
    __typename?: 'Cart';
    id: string;
    total: number;
    subTotal: number;
    discounts: number;
    accountType?: AccountType | null;
    initialBalance?: number | null;
    appliedDiscount?: string | null;
    discountType?: string | null;
    status?: CartStatus | null;
    addons?: Array<{
      __typename?: 'Addon';
      name: string;
      type: AddonType;
      percentageIncrease: number;
    } | null> | null;
  };
};

export type RequestKycMutationVariables = Exact<{ [key: string]: never }>;

export type RequestKycMutation = {
  __typename?: 'Mutation';
  requestKyc?: {
    __typename?: 'KycRequest';
    status: string;
    id: string;
    url: string;
    createdAt: string;
  } | null;
};

export type RequestPaymentForOrderMutationVariables = Exact<{
  orderNumber: Scalars['String']['input'];
  paymentMethod: PaymentMethod;
  secureHash?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
}>;

export type RequestPaymentForOrderMutation = {
  __typename?: 'Mutation';
  requestPaymentForOrder: {
    __typename?: 'OrderResponse';
    id: string;
    orderNumber: string;
    status: OrderStatus;
    total: number;
    subTotal: number;
    discounts: number;
    accountType: AccountType;
    initialBalance: number;
    token?: string | null;
    createdAt?: string | null;
    expiresAt?: string | null;
    currency?: string | null;
    leverage?: number | null;
    paymentIntent: {
      __typename?: 'Payment';
      id: string;
      status: string;
      gatewayUrl?: string | null;
      reference?: string | null;
      gateway?: PaymentMethod | null;
    };
    user?: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      token?: string | null;
    } | null;
    address?: {
      __typename?: 'OrderAddressPayload';
      countryCode: string;
      email?: string | null;
    } | null;
    brokerAccount?: {
      __typename?: 'BrokerAccount';
      id: string;
      initialBalance: number;
      login: string;
      server: string;
      masterPassword: string;
      investorPassword: string;
      accountName?: string | null;
      leverage: number;
    } | null;
    addons?: Array<{
      __typename?: 'Addon';
      name: string;
      type: AddonType;
      percentageIncrease: number;
    } | null> | null;
  };
};

export type ResetPasswordMutationVariables = Exact<{
  id: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: { __typename?: 'ForgottenPasswordResponse'; status: string };
};

export type RestartAccountMutationVariables = Exact<{
  login: Scalars['String']['input'];
}>;

export type RestartAccountMutation = {
  __typename?: 'Mutation';
  restartAccount: {
    __typename?: 'OrderResponse';
    id: string;
    orderNumber: string;
    status: OrderStatus;
    total: number;
    subTotal: number;
    discounts: number;
    accountType: AccountType;
    initialBalance: number;
    token?: string | null;
    createdAt?: string | null;
    expiresAt?: string | null;
    currency?: string | null;
    leverage?: number | null;
    brokerAccount?: {
      __typename?: 'BrokerAccount';
      id: string;
      initialBalance: number;
      login: string;
      server: string;
      masterPassword: string;
      investorPassword: string;
      accountName?: string | null;
      leverage: number;
    } | null;
    user?: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      token?: string | null;
    } | null;
    address?: {
      __typename?: 'OrderAddressPayload';
      countryCode: string;
      email?: string | null;
    } | null;
    addons?: Array<{
      __typename?: 'Addon';
      name: string;
      type: AddonType;
      percentageIncrease: number;
    } | null> | null;
  };
};

export type SetDiscountOnCartMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
}>;

export type SetDiscountOnCartMutation = {
  __typename?: 'Mutation';
  setDiscountOnCart: {
    __typename?: 'Cart';
    id: string;
    total: number;
    subTotal: number;
    discounts: number;
    accountType?: AccountType | null;
    initialBalance?: number | null;
    appliedDiscount?: string | null;
    discountType?: string | null;
    status?: CartStatus | null;
    addons?: Array<{
      __typename?: 'Addon';
      name: string;
      type: AddonType;
      percentageIncrease: number;
    } | null> | null;
  };
};

export type SetPlanOnCartMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  accountType: AccountType;
  initialBalance: Scalars['Float']['input'];
}>;

export type SetPlanOnCartMutation = {
  __typename?: 'Mutation';
  setPlanOnCart: {
    __typename?: 'Cart';
    id: string;
    total: number;
    subTotal: number;
    discounts: number;
    accountType?: AccountType | null;
    initialBalance?: number | null;
    appliedDiscount?: string | null;
    discountType?: string | null;
    status?: CartStatus | null;
    addons?: Array<{
      __typename?: 'Addon';
      name: string;
      type: AddonType;
      percentageIncrease: number;
    } | null> | null;
  };
};

export type SocialLoginMutationVariables = Exact<{
  provider: SocialLoginProvider;
  authorization: Scalars['String']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
}>;

export type SocialLoginMutation = {
  __typename?: 'Mutation';
  socialLogin?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    token?: string | null;
    emailVerifiedAt?: string | null;
    phoneVerifiedAt?: string | null;
    phone?: string | null;
    affiliateUsername: string;
    preferredLocale: string;
    countryCode?: string | null;
    role?: string | null;
    createdAt?: string | null;
    kycStatus?: KycStatus | null;
  } | null;
};

export type ToggleAddonOnCartMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  addonType?: InputMaybe<AddonType>;
}>;

export type ToggleAddonOnCartMutation = {
  __typename?: 'Mutation';
  toggleAddonOnCart: {
    __typename?: 'Cart';
    id: string;
    total: number;
    subTotal: number;
    discounts: number;
    accountType?: AccountType | null;
    initialBalance?: number | null;
    appliedDiscount?: string | null;
    discountType?: string | null;
    status?: CartStatus | null;
    addons?: Array<{
      __typename?: 'Addon';
      name: string;
      type: AddonType;
      percentageIncrease: number;
    } | null> | null;
  };
};

export type UpdateUserMutationVariables = Exact<{
  user: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    token?: string | null;
    emailVerifiedAt?: string | null;
    phoneVerifiedAt?: string | null;
    phone?: string | null;
    affiliateUsername: string;
  } | null;
};

export type CheckBrokerAccountRestartQueryVariables = Exact<{
  login: Scalars['String']['input'];
}>;

export type CheckBrokerAccountRestartQuery = {
  __typename?: 'Query';
  checkAccountRestart: {
    __typename?: 'AccountRestartResponse';
    id: string;
    restarted?: boolean | null;
    originalPrice?: number | null;
    newPrice?: number | null;
    eligible?: boolean | null;
    expiryTime?: string | null;
    currency?: string | null;
    percent?: number | null;
  };
};

export type CurrentWeekCalendarDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentWeekCalendarDataQuery = {
  __typename?: 'Query';
  currentWeekCalendarData?: Array<{
    __typename?: 'CalendarRecord';
    id?: string | null;
    title?: string | null;
    country?: string | null;
    date?: string | null;
    impact?: string | null;
    forecast?: string | null;
    previous?: string | null;
    url?: string | null;
  } | null> | null;
};

export type GeolocationQueryVariables = Exact<{ [key: string]: never }>;

export type GeolocationQuery = {
  __typename?: 'Query';
  geolocation: {
    __typename?: 'GeolocationResponse';
    country?: string | null;
    countryName?: string | null;
    city?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    zipCode?: string | null;
  };
};

export type GetAccountTradingDiaryQueryVariables = Exact<{
  login?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetAccountTradingDiaryQuery = {
  __typename?: 'Query';
  accountTradingDiary: Array<{
    __typename?: 'MonevisAccountTradingDiary';
    id?: number | null;
    login?: string | null;
    year?: string | null;
    month?: string | null;
    day?: string | null;
    startingBalance?: number | null;
    startingEquity?: number | null;
    endingBalance?: number | null;
    tradesExecuted?: number | null;
    lotsExecuted?: number | null;
    endingEquity?: number | null;
  } | null>;
};

export type GetAllUserCertificatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllUserCertificatesQuery = {
  __typename?: 'Query';
  getAllUserCertificates?: Array<{
    __typename?: 'Certificate';
    id: string;
    ulidx: string;
    firstName: string;
    lastName: string;
    email: string;
    amount?: number | null;
    type: CertificateType;
    publicUrl: string;
    privateUrl: string;
    createdAt: string;
    user?: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      token?: string | null;
      emailVerifiedAt?: string | null;
      phoneVerifiedAt?: string | null;
      phone?: string | null;
      affiliateUsername: string;
      preferredLocale: string;
      countryCode?: string | null;
      role?: string | null;
      createdAt?: string | null;
      kycStatus?: KycStatus | null;
    } | null;
    metadata: {
      __typename?: 'CertificateMetadata';
      amount?: number | null;
      privateKey?: string | null;
    };
  } | null> | null;
};

export type GetBrokerAccountLotsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetBrokerAccountLotsQuery = {
  __typename?: 'Query';
  brokerAccountLotsRange: {
    __typename?: 'BrokerAccountLotsRange';
    lowerBoundary: number;
    upperBoundary: number;
  };
};

export type GetBrokerAccountStatisticsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetBrokerAccountStatisticsQuery = {
  __typename?: 'Query';
  brokerAccountStatistics: {
    __typename?: 'BrokerAccountStatistics';
    id: string;
    equity: number;
    balance: number;
    floatingPnL: number;
    updatedUtc: string;
    restarted?: boolean | null;
    stats?: {
      __typename?: 'MonevisAnalysis';
      login: number;
      outcome: MonevisAnalysisResultOutcome;
      autoLiquidationValue: number;
      analysis?: Array<{
        __typename?: 'MonevisAnalysisRule';
        result: MonevisAnalysisResultOutcome;
        identifier: string;
        absoluteValueType: string;
        currentAbsoluteValue: number;
        targetAbsoluteValue: number;
        percentageValueType: string;
        currentPercentageValue: number;
        targetPercentageValue: number;
        relativeValueType: string;
        currentRelativeValue: number;
        targetRelativeValue: number;
        ruleType: string;
      } | null> | null;
    } | null;
  };
};

export type GetBrokerAccountQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetBrokerAccountQuery = {
  __typename?: 'Query';
  brokerAccount: {
    __typename?: 'BrokerAccount';
    id: string;
    initialBalance: number;
    currentBalance: number;
    currentEquity: number;
    currentPnl: number;
    lastUpdatedAt?: string | null;
    login: string;
    server: string;
    masterPassword: string;
    investorPassword: string;
    accountName?: string | null;
    accountType: AccountType;
    leverage: number;
    createdAt: string;
    challengeStatus: ChallengeStatus;
    failedAt?: string | null;
    payoutPeriod?: number | null;
    profitSplit?: number | null;
  };
};

export type GetBrokerAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBrokerAccountsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    brokerAccounts: Array<{
      __typename?: 'BrokerAccount';
      id: string;
      initialBalance: number;
      currentBalance: number;
      currentEquity: number;
      currentPnl: number;
      lastUpdatedAt?: string | null;
      login: string;
      server: string;
      masterPassword: string;
      investorPassword: string;
      accountName?: string | null;
      accountType: AccountType;
      leverage: number;
      createdAt: string;
      challengeStatus: ChallengeStatus;
      failedAt?: string | null;
      payoutPeriod?: number | null;
      profitSplit?: number | null;
    }>;
  };
};

export type GetClosedPositionsQueryVariables = Exact<{
  login?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetClosedPositionsQuery = {
  __typename?: 'Query';
  closedPositions?: Array<{
    __typename?: 'AccountTradingPosition';
    id?: string | null;
    login?: number | null;
    symbol?: string | null;
    profit?: number | null;
    totalProfit?: number | null;
    fees?: number | null;
    dateOpen?: string | null;
    dateClose?: string | null;
    priceOpen?: number | null;
    priceClose?: number | null;
    duration?: number | null;
    lots?: number | null;
    action?: AccountTradingPositionAction | null;
    user?: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      token?: string | null;
      emailVerifiedAt?: string | null;
      phoneVerifiedAt?: string | null;
      phone?: string | null;
      affiliateUsername: string;
      preferredLocale: string;
      countryCode?: string | null;
      role?: string | null;
      createdAt?: string | null;
      kycStatus?: KycStatus | null;
    } | null;
  } | null> | null;
};

export type GetForgottenPasswordTokenQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetForgottenPasswordTokenQuery = {
  __typename?: 'Query';
  forgottenPassword: {
    __typename?: 'ForgottenPasswordResponse';
    status: string;
  };
};

export type GetFundedAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetFundedAccountsQuery = {
  __typename?: 'Query';
  fundedAccounts: Array<{
    __typename?: 'FundedAccount';
    id: string;
    accountName: string;
    initialBalance: number;
    login: string;
    currentBalance: number;
    openedTrades: number;
    deletedAt?: string | null;
    withdrawable: number;
    eligibleForWithdrawal: boolean;
    payoutFrequency: number;
    profitSplit: number;
    nextEligiblePayout?: string | null;
  }>;
};

export type GetOrderQueryVariables = Exact<{
  orderNumber: Scalars['String']['input'];
  secureHash?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetOrderQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'OrderResponse';
    id: string;
    orderNumber: string;
    status: OrderStatus;
    total: number;
    subTotal: number;
    discounts: number;
    accountType: AccountType;
    initialBalance: number;
    token?: string | null;
    createdAt?: string | null;
    expiresAt?: string | null;
    currency?: string | null;
    leverage?: number | null;
    brokerAccount?: {
      __typename?: 'BrokerAccount';
      id: string;
      initialBalance: number;
      login: string;
      server: string;
      masterPassword: string;
      investorPassword: string;
      accountName?: string | null;
      leverage: number;
    } | null;
    user?: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      token?: string | null;
    } | null;
    address?: {
      __typename?: 'OrderAddressPayload';
      countryCode: string;
      email?: string | null;
    } | null;
    addons?: Array<{
      __typename?: 'Addon';
      name: string;
      type: AddonType;
      percentageIncrease: number;
    } | null> | null;
  };
};

export type GetOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrdersQuery = {
  __typename?: 'Query';
  orders: Array<{
    __typename?: 'OrderResponse';
    id: string;
    orderNumber: string;
    status: OrderStatus;
    total: number;
    subTotal: number;
    discounts: number;
    accountType: AccountType;
    initialBalance: number;
    token?: string | null;
    createdAt?: string | null;
    expiresAt?: string | null;
    currency?: string | null;
    leverage?: number | null;
    brokerAccount?: {
      __typename?: 'BrokerAccount';
      id: string;
      initialBalance: number;
      login: string;
      server: string;
      masterPassword: string;
      investorPassword: string;
      accountName?: string | null;
      leverage: number;
    } | null;
    user?: {
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      token?: string | null;
    } | null;
    address?: {
      __typename?: 'OrderAddressPayload';
      countryCode: string;
      email?: string | null;
    } | null;
    addons?: Array<{
      __typename?: 'Addon';
      name: string;
      type: AddonType;
      percentageIncrease: number;
    } | null> | null;
  }>;
};

export type GetWalletQueryVariables = Exact<{ [key: string]: never }>;

export type GetWalletQuery = {
  __typename?: 'Query';
  wallet: {
    __typename?: 'Wallet';
    balance: number;
    transactions: Array<{
      __typename?: 'Transaction';
      id: string;
      amount: number;
      type: string;
      createdAt: string;
      comment?: string | null;
      status?: string | null;
      transactionType: TransactionType;
      releasedAt?: string | null;
    }>;
  };
};

export type GetViewerQueryVariables = Exact<{ [key: string]: never }>;

export type GetViewerQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    token?: string | null;
    emailVerifiedAt?: string | null;
    phoneVerifiedAt?: string | null;
    phone?: string | null;
    affiliateUsername: string;
    preferredLocale: string;
    countryCode?: string | null;
    role?: string | null;
    createdAt?: string | null;
    kycStatus?: KycStatus | null;
  };
};

export const UserFragmentDoc = gql`
  fragment User on User {
    id
    firstName
    lastName
    email
    token
    emailVerifiedAt
    phoneVerifiedAt
    phone
    affiliateUsername
    preferredLocale
    countryCode
    role
    createdAt
    kycStatus
  }
`;
export const AccountTradingPositionFragmentDoc = gql`
  fragment AccountTradingPosition on AccountTradingPosition {
    id
    user {
      ...User
    }
    login
    symbol
    profit
    totalProfit
    fees
    dateOpen
    dateClose
    priceOpen
    priceClose
    duration
    lots
    action
  }
  ${UserFragmentDoc}
`;
export const MonevisAnalysisRuleFragmentDoc = gql`
  fragment MonevisAnalysisRule on MonevisAnalysisRule {
    result
    identifier
    absoluteValueType
    currentAbsoluteValue
    targetAbsoluteValue
    percentageValueType
    currentPercentageValue
    targetPercentageValue
    relativeValueType
    currentRelativeValue
    targetRelativeValue
    ruleType
  }
`;
export const BrokerAccountStatisticsFragmentDoc = gql`
  fragment BrokerAccountStatistics on BrokerAccountStatistics {
    id
    equity
    balance
    floatingPnL
    updatedUtc
    restarted
    stats {
      login
      analysis {
        ...MonevisAnalysisRule
      }
      outcome
      autoLiquidationValue
    }
  }
  ${MonevisAnalysisRuleFragmentDoc}
`;
export const BrokerAccountTransactionFragmentDoc = gql`
  fragment BrokerAccountTransaction on BrokerAccountTransaction {
    id
    userId
    amount
    type
    createdAt
    status
    releasedAt
    comment
    accountId
    profitSplit
    profitSplitAmount
    performanceAmount
  }
`;
export const BrokerAccountFragmentDoc = gql`
  fragment BrokerAccount on BrokerAccount {
    id
    initialBalance
    currentBalance
    currentEquity
    currentPnl
    lastUpdatedAt
    login
    server
    masterPassword
    investorPassword
    accountName
    accountType
    leverage
    createdAt
    challengeStatus
    failedAt
    payoutPeriod
    profitSplit
  }
`;
export const CalendarRecordFragmentDoc = gql`
  fragment CalendarRecord on CalendarRecord {
    id
    title
    country
    date
    impact
    forecast
    previous
    url
  }
`;
export const AddonFragmentDoc = gql`
  fragment Addon on Addon {
    name
    type
    percentageIncrease
  }
`;
export const CartFragmentDoc = gql`
  fragment Cart on Cart {
    id
    total
    subTotal
    discounts
    accountType
    initialBalance
    appliedDiscount
    discountType
    status
    addons {
      name
      type
      percentageIncrease
    }
    addons {
      ...Addon
    }
  }
  ${AddonFragmentDoc}
`;
export const CertificateFragmentDoc = gql`
  fragment Certificate on Certificate {
    id
    user {
      ...User
    }
    ulidx
    firstName
    lastName
    email
    metadata {
      amount
      privateKey
    }
    amount
    type
    publicUrl
    privateUrl
    createdAt
  }
  ${UserFragmentDoc}
`;
export const FundedAccountFragmentDoc = gql`
  fragment FundedAccount on FundedAccount {
    id
    accountName
    initialBalance
    login
    currentBalance
    openedTrades
    deletedAt
    withdrawable
    eligibleForWithdrawal
    payoutFrequency
    profitSplit
    nextEligiblePayout
  }
`;
export const GeolocationResponseFragmentDoc = gql`
  fragment GeolocationResponse on GeolocationResponse {
    country
    countryName
    city
    latitude
    longitude
    zipCode
  }
`;
export const KycRequestFragmentDoc = gql`
  fragment KycRequest on KycRequest {
    status
    id
    url
    createdAt
  }
`;
export const MonevisAccountTradingDiaryFragmentDoc = gql`
  fragment MonevisAccountTradingDiary on MonevisAccountTradingDiary {
    id
    login
    year
    month
    day
    startingBalance
    startingEquity
    endingBalance
    tradesExecuted
    lotsExecuted
    endingEquity
  }
`;
export const MonevisAnalysisFragmentDoc = gql`
  fragment MonevisAnalysis on MonevisAnalysis {
    login
    outcome
    autoLiquidationValue
    analysis {
      ...MonevisAnalysisRule
    }
  }
  ${MonevisAnalysisRuleFragmentDoc}
`;
export const OrderResponseFragmentDoc = gql`
  fragment OrderResponse on OrderResponse {
    id
    orderNumber
    status
    total
    subTotal
    discounts
    accountType
    initialBalance
    token
    createdAt
    expiresAt
    currency
    leverage
    brokerAccount {
      id
      initialBalance
      login
      server
      masterPassword
      investorPassword
      accountName
      leverage
    }
    user {
      id
      firstName
      lastName
      email
      token
    }
    address {
      countryCode
      email
    }
    addons {
      ...Addon
    }
  }
  ${AddonFragmentDoc}
`;
export const PaymentFragmentDoc = gql`
  fragment Payment on Payment {
    id
    status
    gatewayUrl
    reference
    gateway
  }
`;
export const OrderWithIntentFragmentDoc = gql`
  fragment OrderWithIntent on OrderResponse {
    id
    orderNumber
    status
    total
    subTotal
    discounts
    accountType
    initialBalance
    token
    createdAt
    currency
    leverage
    brokerAccount {
      id
      initialBalance
      login
      server
      masterPassword
      investorPassword
      accountName
      leverage
    }
    user {
      id
      firstName
      lastName
      email
      token
    }
    address {
      countryCode
      email
    }
    paymentIntent {
      ...Payment
    }
    addons {
      ...Addon
    }
  }
  ${PaymentFragmentDoc}
  ${AddonFragmentDoc}
`;
export const TransactionFragmentDoc = gql`
  fragment Transaction on Transaction {
    id
    amount
    type
    createdAt
    comment
    status
    transactionType
    releasedAt
  }
`;
export const WalletFragmentDoc = gql`
  fragment Wallet on Wallet {
    balance
    transactions {
      id
      amount
      type
      createdAt
      comment
      status
      transactionType
      releasedAt
    }
  }
`;
export const ArchiveBrokerAccountDocument = gql`
  mutation ArchiveBrokerAccount($login: String!) {
    archiveBrokerAccount(login: $login) {
      ...BrokerAccount
    }
  }
  ${BrokerAccountFragmentDoc}
`;
export type ArchiveBrokerAccountMutationFn = Apollo.MutationFunction<
  ArchiveBrokerAccountMutation,
  ArchiveBrokerAccountMutationVariables
>;

/**
 * __useArchiveBrokerAccountMutation__
 *
 * To run a mutation, you first call `useArchiveBrokerAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveBrokerAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveBrokerAccountMutation, { data, loading, error }] = useArchiveBrokerAccountMutation({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useArchiveBrokerAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveBrokerAccountMutation,
    ArchiveBrokerAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArchiveBrokerAccountMutation,
    ArchiveBrokerAccountMutationVariables
  >(ArchiveBrokerAccountDocument, options);
}
export type ArchiveBrokerAccountMutationHookResult = ReturnType<
  typeof useArchiveBrokerAccountMutation
>;
export type ArchiveBrokerAccountMutationResult =
  Apollo.MutationResult<ArchiveBrokerAccountMutation>;
export type ArchiveBrokerAccountMutationOptions = Apollo.BaseMutationOptions<
  ArchiveBrokerAccountMutation,
  ArchiveBrokerAccountMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($payload: ChangePasswordInput!) {
    changePassword(payload: $payload) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult =
  Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const ForgottenPasswordDocument = gql`
  mutation ForgottenPassword($email: String!) {
    forgottenPassword(email: $email) {
      status
    }
  }
`;
export type ForgottenPasswordMutationFn = Apollo.MutationFunction<
  ForgottenPasswordMutation,
  ForgottenPasswordMutationVariables
>;

/**
 * __useForgottenPasswordMutation__
 *
 * To run a mutation, you first call `useForgottenPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgottenPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgottenPasswordMutation, { data, loading, error }] = useForgottenPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgottenPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForgottenPasswordMutation,
    ForgottenPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForgottenPasswordMutation,
    ForgottenPasswordMutationVariables
  >(ForgottenPasswordDocument, options);
}
export type ForgottenPasswordMutationHookResult = ReturnType<
  typeof useForgottenPasswordMutation
>;
export type ForgottenPasswordMutationResult =
  Apollo.MutationResult<ForgottenPasswordMutation>;
export type ForgottenPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgottenPasswordMutation,
  ForgottenPasswordMutationVariables
>;
export const ImpersonateUserDocument = gql`
  mutation ImpersonateUser($username: String!) {
    impersonate(username: $username) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type ImpersonateUserMutationFn = Apollo.MutationFunction<
  ImpersonateUserMutation,
  ImpersonateUserMutationVariables
>;

/**
 * __useImpersonateUserMutation__
 *
 * To run a mutation, you first call `useImpersonateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImpersonateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [impersonateUserMutation, { data, loading, error }] = useImpersonateUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useImpersonateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ImpersonateUserMutation,
    ImpersonateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ImpersonateUserMutation,
    ImpersonateUserMutationVariables
  >(ImpersonateUserDocument, options);
}
export type ImpersonateUserMutationHookResult = ReturnType<
  typeof useImpersonateUserMutation
>;
export type ImpersonateUserMutationResult =
  Apollo.MutationResult<ImpersonateUserMutation>;
export type ImpersonateUserMutationOptions = Apollo.BaseMutationOptions<
  ImpersonateUserMutation,
  ImpersonateUserMutationVariables
>;
export const LoginUserDocument = gql`
  mutation LoginUser($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type LoginUserMutationFn = Apollo.MutationFunction<
  LoginUserMutation,
  LoginUserMutationVariables
>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginUserMutation,
    LoginUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(
    LoginUserDocument,
    options,
  );
}
export type LoginUserMutationHookResult = ReturnType<
  typeof useLoginUserMutation
>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<
  LoginUserMutation,
  LoginUserMutationVariables
>;
export const PlaceOrderDocument = gql`
  mutation PlaceOrder(
    $cartId: String!
    $address: OrderAddress!
    $paymentMethod: PaymentMethod!
    $registrationData: RegistrationInputForOrder
  ) {
    placeOrder(
      cartId: $cartId
      address: $address
      paymentMethod: $paymentMethod
      registrationData: $registrationData
    ) {
      ...OrderResponse
    }
  }
  ${OrderResponseFragmentDoc}
`;
export type PlaceOrderMutationFn = Apollo.MutationFunction<
  PlaceOrderMutation,
  PlaceOrderMutationVariables
>;

/**
 * __usePlaceOrderMutation__
 *
 * To run a mutation, you first call `usePlaceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeOrderMutation, { data, loading, error }] = usePlaceOrderMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *      address: // value for 'address'
 *      paymentMethod: // value for 'paymentMethod'
 *      registrationData: // value for 'registrationData'
 *   },
 * });
 */
export function usePlaceOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlaceOrderMutation,
    PlaceOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PlaceOrderMutation, PlaceOrderMutationVariables>(
    PlaceOrderDocument,
    options,
  );
}
export type PlaceOrderMutationHookResult = ReturnType<
  typeof usePlaceOrderMutation
>;
export type PlaceOrderMutationResult =
  Apollo.MutationResult<PlaceOrderMutation>;
export type PlaceOrderMutationOptions = Apollo.BaseMutationOptions<
  PlaceOrderMutation,
  PlaceOrderMutationVariables
>;
export const PlaceTrialOrderDocument = gql`
  mutation PlaceTrialOrder(
    $balance: Float!
    $address: OrderAddress!
    $registrationData: RegistrationInput
  ) {
    placeTrialOrder(
      initialBalance: $balance
      address: $address
      registrationData: $registrationData
    ) {
      token
      user {
        ...User
      }
      brokerAccount {
        ...BrokerAccount
      }
    }
  }
  ${UserFragmentDoc}
  ${BrokerAccountFragmentDoc}
`;
export type PlaceTrialOrderMutationFn = Apollo.MutationFunction<
  PlaceTrialOrderMutation,
  PlaceTrialOrderMutationVariables
>;

/**
 * __usePlaceTrialOrderMutation__
 *
 * To run a mutation, you first call `usePlaceTrialOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceTrialOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeTrialOrderMutation, { data, loading, error }] = usePlaceTrialOrderMutation({
 *   variables: {
 *      balance: // value for 'balance'
 *      address: // value for 'address'
 *      registrationData: // value for 'registrationData'
 *   },
 * });
 */
export function usePlaceTrialOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PlaceTrialOrderMutation,
    PlaceTrialOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PlaceTrialOrderMutation,
    PlaceTrialOrderMutationVariables
  >(PlaceTrialOrderDocument, options);
}
export type PlaceTrialOrderMutationHookResult = ReturnType<
  typeof usePlaceTrialOrderMutation
>;
export type PlaceTrialOrderMutationResult =
  Apollo.MutationResult<PlaceTrialOrderMutation>;
export type PlaceTrialOrderMutationOptions = Apollo.BaseMutationOptions<
  PlaceTrialOrderMutation,
  PlaceTrialOrderMutationVariables
>;
export const RegisterUserDocument = gql`
  mutation RegisterUser($user: RegistrationInput!) {
    register(user: $user) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type RegisterUserMutationFn = Apollo.MutationFunction<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(RegisterUserDocument, options);
}
export type RegisterUserMutationHookResult = ReturnType<
  typeof useRegisterUserMutation
>;
export type RegisterUserMutationResult =
  Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
export const RequestBrokerWithdrawalDocument = gql`
  mutation RequestBrokerWithdrawal($id: String!, $amount: Float!) {
    withdrawFromBrokerAccount(id: $id, amountToWithdraw: $amount) {
      id
    }
  }
`;
export type RequestBrokerWithdrawalMutationFn = Apollo.MutationFunction<
  RequestBrokerWithdrawalMutation,
  RequestBrokerWithdrawalMutationVariables
>;

/**
 * __useRequestBrokerWithdrawalMutation__
 *
 * To run a mutation, you first call `useRequestBrokerWithdrawalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestBrokerWithdrawalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestBrokerWithdrawalMutation, { data, loading, error }] = useRequestBrokerWithdrawalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useRequestBrokerWithdrawalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestBrokerWithdrawalMutation,
    RequestBrokerWithdrawalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestBrokerWithdrawalMutation,
    RequestBrokerWithdrawalMutationVariables
  >(RequestBrokerWithdrawalDocument, options);
}
export type RequestBrokerWithdrawalMutationHookResult = ReturnType<
  typeof useRequestBrokerWithdrawalMutation
>;
export type RequestBrokerWithdrawalMutationResult =
  Apollo.MutationResult<RequestBrokerWithdrawalMutation>;
export type RequestBrokerWithdrawalMutationOptions = Apollo.BaseMutationOptions<
  RequestBrokerWithdrawalMutation,
  RequestBrokerWithdrawalMutationVariables
>;
export const RequestCartDocument = gql`
  mutation RequestCart($cartId: String) {
    requestCart(id: $cartId) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type RequestCartMutationFn = Apollo.MutationFunction<
  RequestCartMutation,
  RequestCartMutationVariables
>;

/**
 * __useRequestCartMutation__
 *
 * To run a mutation, you first call `useRequestCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCartMutation, { data, loading, error }] = useRequestCartMutation({
 *   variables: {
 *      cartId: // value for 'cartId'
 *   },
 * });
 */
export function useRequestCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestCartMutation,
    RequestCartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestCartMutation, RequestCartMutationVariables>(
    RequestCartDocument,
    options,
  );
}
export type RequestCartMutationHookResult = ReturnType<
  typeof useRequestCartMutation
>;
export type RequestCartMutationResult =
  Apollo.MutationResult<RequestCartMutation>;
export type RequestCartMutationOptions = Apollo.BaseMutationOptions<
  RequestCartMutation,
  RequestCartMutationVariables
>;
export const RequestKycDocument = gql`
  mutation RequestKYC {
    requestKyc {
      ...KycRequest
    }
  }
  ${KycRequestFragmentDoc}
`;
export type RequestKycMutationFn = Apollo.MutationFunction<
  RequestKycMutation,
  RequestKycMutationVariables
>;

/**
 * __useRequestKycMutation__
 *
 * To run a mutation, you first call `useRequestKycMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestKycMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestKycMutation, { data, loading, error }] = useRequestKycMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestKycMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestKycMutation,
    RequestKycMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestKycMutation, RequestKycMutationVariables>(
    RequestKycDocument,
    options,
  );
}
export type RequestKycMutationHookResult = ReturnType<
  typeof useRequestKycMutation
>;
export type RequestKycMutationResult =
  Apollo.MutationResult<RequestKycMutation>;
export type RequestKycMutationOptions = Apollo.BaseMutationOptions<
  RequestKycMutation,
  RequestKycMutationVariables
>;
export const RequestPaymentForOrderDocument = gql`
  mutation RequestPaymentForOrder(
    $orderNumber: String!
    $paymentMethod: PaymentMethod!
    $secureHash: String
    $theme: String
  ) {
    requestPaymentForOrder(
      orderNumber: $orderNumber
      paymentMethod: $paymentMethod
      secureHash: $secureHash
      theme: $theme
    ) {
      ...OrderResponse
      paymentIntent {
        ...Payment
      }
      user {
        id
        firstName
        lastName
        email
      }
      address {
        countryCode
        email
      }
    }
  }
  ${OrderResponseFragmentDoc}
  ${PaymentFragmentDoc}
`;
export type RequestPaymentForOrderMutationFn = Apollo.MutationFunction<
  RequestPaymentForOrderMutation,
  RequestPaymentForOrderMutationVariables
>;

/**
 * __useRequestPaymentForOrderMutation__
 *
 * To run a mutation, you first call `useRequestPaymentForOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPaymentForOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPaymentForOrderMutation, { data, loading, error }] = useRequestPaymentForOrderMutation({
 *   variables: {
 *      orderNumber: // value for 'orderNumber'
 *      paymentMethod: // value for 'paymentMethod'
 *      secureHash: // value for 'secureHash'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useRequestPaymentForOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPaymentForOrderMutation,
    RequestPaymentForOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPaymentForOrderMutation,
    RequestPaymentForOrderMutationVariables
  >(RequestPaymentForOrderDocument, options);
}
export type RequestPaymentForOrderMutationHookResult = ReturnType<
  typeof useRequestPaymentForOrderMutation
>;
export type RequestPaymentForOrderMutationResult =
  Apollo.MutationResult<RequestPaymentForOrderMutation>;
export type RequestPaymentForOrderMutationOptions = Apollo.BaseMutationOptions<
  RequestPaymentForOrderMutation,
  RequestPaymentForOrderMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($id: String!, $password: String!) {
    resetPassword(id: $id, password: $password) {
      status
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const RestartAccountDocument = gql`
  mutation RestartAccount($login: String!) {
    restartAccount(login: $login) {
      ...OrderResponse
    }
  }
  ${OrderResponseFragmentDoc}
`;
export type RestartAccountMutationFn = Apollo.MutationFunction<
  RestartAccountMutation,
  RestartAccountMutationVariables
>;

/**
 * __useRestartAccountMutation__
 *
 * To run a mutation, you first call `useRestartAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartAccountMutation, { data, loading, error }] = useRestartAccountMutation({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useRestartAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestartAccountMutation,
    RestartAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestartAccountMutation,
    RestartAccountMutationVariables
  >(RestartAccountDocument, options);
}
export type RestartAccountMutationHookResult = ReturnType<
  typeof useRestartAccountMutation
>;
export type RestartAccountMutationResult =
  Apollo.MutationResult<RestartAccountMutation>;
export type RestartAccountMutationOptions = Apollo.BaseMutationOptions<
  RestartAccountMutation,
  RestartAccountMutationVariables
>;
export const SetDiscountOnCartDocument = gql`
  mutation SetDiscountOnCart($id: String, $code: String!) {
    setDiscountOnCart(id: $id, code: $code) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type SetDiscountOnCartMutationFn = Apollo.MutationFunction<
  SetDiscountOnCartMutation,
  SetDiscountOnCartMutationVariables
>;

/**
 * __useSetDiscountOnCartMutation__
 *
 * To run a mutation, you first call `useSetDiscountOnCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDiscountOnCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDiscountOnCartMutation, { data, loading, error }] = useSetDiscountOnCartMutation({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useSetDiscountOnCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDiscountOnCartMutation,
    SetDiscountOnCartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetDiscountOnCartMutation,
    SetDiscountOnCartMutationVariables
  >(SetDiscountOnCartDocument, options);
}
export type SetDiscountOnCartMutationHookResult = ReturnType<
  typeof useSetDiscountOnCartMutation
>;
export type SetDiscountOnCartMutationResult =
  Apollo.MutationResult<SetDiscountOnCartMutation>;
export type SetDiscountOnCartMutationOptions = Apollo.BaseMutationOptions<
  SetDiscountOnCartMutation,
  SetDiscountOnCartMutationVariables
>;
export const SetPlanOnCartDocument = gql`
  mutation SetPlanOnCart(
    $id: String
    $accountType: AccountType!
    $initialBalance: Float!
  ) {
    setPlanOnCart(
      id: $id
      accountType: $accountType
      initialBalance: $initialBalance
    ) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type SetPlanOnCartMutationFn = Apollo.MutationFunction<
  SetPlanOnCartMutation,
  SetPlanOnCartMutationVariables
>;

/**
 * __useSetPlanOnCartMutation__
 *
 * To run a mutation, you first call `useSetPlanOnCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPlanOnCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPlanOnCartMutation, { data, loading, error }] = useSetPlanOnCartMutation({
 *   variables: {
 *      id: // value for 'id'
 *      accountType: // value for 'accountType'
 *      initialBalance: // value for 'initialBalance'
 *   },
 * });
 */
export function useSetPlanOnCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPlanOnCartMutation,
    SetPlanOnCartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetPlanOnCartMutation,
    SetPlanOnCartMutationVariables
  >(SetPlanOnCartDocument, options);
}
export type SetPlanOnCartMutationHookResult = ReturnType<
  typeof useSetPlanOnCartMutation
>;
export type SetPlanOnCartMutationResult =
  Apollo.MutationResult<SetPlanOnCartMutation>;
export type SetPlanOnCartMutationOptions = Apollo.BaseMutationOptions<
  SetPlanOnCartMutation,
  SetPlanOnCartMutationVariables
>;
export const SocialLoginDocument = gql`
  mutation SocialLogin(
    $provider: SocialLoginProvider!
    $authorization: String!
    $code: String
  ) {
    socialLogin(
      provider: $provider
      authorization: $authorization
      code: $code
    ) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type SocialLoginMutationFn = Apollo.MutationFunction<
  SocialLoginMutation,
  SocialLoginMutationVariables
>;

/**
 * __useSocialLoginMutation__
 *
 * To run a mutation, you first call `useSocialLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialLoginMutation, { data, loading, error }] = useSocialLoginMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      authorization: // value for 'authorization'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useSocialLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SocialLoginMutation,
    SocialLoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SocialLoginMutation, SocialLoginMutationVariables>(
    SocialLoginDocument,
    options,
  );
}
export type SocialLoginMutationHookResult = ReturnType<
  typeof useSocialLoginMutation
>;
export type SocialLoginMutationResult =
  Apollo.MutationResult<SocialLoginMutation>;
export type SocialLoginMutationOptions = Apollo.BaseMutationOptions<
  SocialLoginMutation,
  SocialLoginMutationVariables
>;
export const ToggleAddonOnCartDocument = gql`
  mutation ToggleAddonOnCart($id: String, $addonType: AddonType) {
    toggleAddonOnCart(id: $id, addonType: $addonType) {
      ...Cart
    }
  }
  ${CartFragmentDoc}
`;
export type ToggleAddonOnCartMutationFn = Apollo.MutationFunction<
  ToggleAddonOnCartMutation,
  ToggleAddonOnCartMutationVariables
>;

/**
 * __useToggleAddonOnCartMutation__
 *
 * To run a mutation, you first call `useToggleAddonOnCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAddonOnCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAddonOnCartMutation, { data, loading, error }] = useToggleAddonOnCartMutation({
 *   variables: {
 *      id: // value for 'id'
 *      addonType: // value for 'addonType'
 *   },
 * });
 */
export function useToggleAddonOnCartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleAddonOnCartMutation,
    ToggleAddonOnCartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleAddonOnCartMutation,
    ToggleAddonOnCartMutationVariables
  >(ToggleAddonOnCartDocument, options);
}
export type ToggleAddonOnCartMutationHookResult = ReturnType<
  typeof useToggleAddonOnCartMutation
>;
export type ToggleAddonOnCartMutationResult =
  Apollo.MutationResult<ToggleAddonOnCartMutation>;
export type ToggleAddonOnCartMutationOptions = Apollo.BaseMutationOptions<
  ToggleAddonOnCartMutation,
  ToggleAddonOnCartMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($user: UpdateUserInput!) {
    updateUser(user: $user) {
      id
      firstName
      lastName
      email
      token
      emailVerifiedAt
      phoneVerifiedAt
      phone
      affiliateUsername
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const CheckBrokerAccountRestartDocument = gql`
  query CheckBrokerAccountRestart($login: String!) {
    checkAccountRestart(login: $login) {
      id
      restarted
      originalPrice
      newPrice
      eligible
      expiryTime
      currency
      percent
    }
  }
`;

/**
 * __useCheckBrokerAccountRestartQuery__
 *
 * To run a query within a React component, call `useCheckBrokerAccountRestartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckBrokerAccountRestartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckBrokerAccountRestartQuery({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useCheckBrokerAccountRestartQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckBrokerAccountRestartQuery,
    CheckBrokerAccountRestartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckBrokerAccountRestartQuery,
    CheckBrokerAccountRestartQueryVariables
  >(CheckBrokerAccountRestartDocument, options);
}
export function useCheckBrokerAccountRestartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckBrokerAccountRestartQuery,
    CheckBrokerAccountRestartQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckBrokerAccountRestartQuery,
    CheckBrokerAccountRestartQueryVariables
  >(CheckBrokerAccountRestartDocument, options);
}
export type CheckBrokerAccountRestartQueryHookResult = ReturnType<
  typeof useCheckBrokerAccountRestartQuery
>;
export type CheckBrokerAccountRestartLazyQueryHookResult = ReturnType<
  typeof useCheckBrokerAccountRestartLazyQuery
>;
export type CheckBrokerAccountRestartQueryResult = Apollo.QueryResult<
  CheckBrokerAccountRestartQuery,
  CheckBrokerAccountRestartQueryVariables
>;
export const CurrentWeekCalendarDataDocument = gql`
  query CurrentWeekCalendarData {
    currentWeekCalendarData {
      ...CalendarRecord
    }
  }
  ${CalendarRecordFragmentDoc}
`;

/**
 * __useCurrentWeekCalendarDataQuery__
 *
 * To run a query within a React component, call `useCurrentWeekCalendarDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentWeekCalendarDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentWeekCalendarDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentWeekCalendarDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentWeekCalendarDataQuery,
    CurrentWeekCalendarDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentWeekCalendarDataQuery,
    CurrentWeekCalendarDataQueryVariables
  >(CurrentWeekCalendarDataDocument, options);
}
export function useCurrentWeekCalendarDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentWeekCalendarDataQuery,
    CurrentWeekCalendarDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentWeekCalendarDataQuery,
    CurrentWeekCalendarDataQueryVariables
  >(CurrentWeekCalendarDataDocument, options);
}
export type CurrentWeekCalendarDataQueryHookResult = ReturnType<
  typeof useCurrentWeekCalendarDataQuery
>;
export type CurrentWeekCalendarDataLazyQueryHookResult = ReturnType<
  typeof useCurrentWeekCalendarDataLazyQuery
>;
export type CurrentWeekCalendarDataQueryResult = Apollo.QueryResult<
  CurrentWeekCalendarDataQuery,
  CurrentWeekCalendarDataQueryVariables
>;
export const GeolocationDocument = gql`
  query Geolocation {
    geolocation {
      ...GeolocationResponse
    }
  }
  ${GeolocationResponseFragmentDoc}
`;

/**
 * __useGeolocationQuery__
 *
 * To run a query within a React component, call `useGeolocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeolocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeolocationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGeolocationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GeolocationQuery,
    GeolocationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GeolocationQuery, GeolocationQueryVariables>(
    GeolocationDocument,
    options,
  );
}
export function useGeolocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GeolocationQuery,
    GeolocationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GeolocationQuery, GeolocationQueryVariables>(
    GeolocationDocument,
    options,
  );
}
export type GeolocationQueryHookResult = ReturnType<typeof useGeolocationQuery>;
export type GeolocationLazyQueryHookResult = ReturnType<
  typeof useGeolocationLazyQuery
>;
export type GeolocationQueryResult = Apollo.QueryResult<
  GeolocationQuery,
  GeolocationQueryVariables
>;
export const GetAccountTradingDiaryDocument = gql`
  query GetAccountTradingDiary($login: String) {
    accountTradingDiary(login: $login) {
      ...MonevisAccountTradingDiary
    }
  }
  ${MonevisAccountTradingDiaryFragmentDoc}
`;

/**
 * __useGetAccountTradingDiaryQuery__
 *
 * To run a query within a React component, call `useGetAccountTradingDiaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountTradingDiaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountTradingDiaryQuery({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useGetAccountTradingDiaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAccountTradingDiaryQuery,
    GetAccountTradingDiaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountTradingDiaryQuery,
    GetAccountTradingDiaryQueryVariables
  >(GetAccountTradingDiaryDocument, options);
}
export function useGetAccountTradingDiaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountTradingDiaryQuery,
    GetAccountTradingDiaryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountTradingDiaryQuery,
    GetAccountTradingDiaryQueryVariables
  >(GetAccountTradingDiaryDocument, options);
}
export type GetAccountTradingDiaryQueryHookResult = ReturnType<
  typeof useGetAccountTradingDiaryQuery
>;
export type GetAccountTradingDiaryLazyQueryHookResult = ReturnType<
  typeof useGetAccountTradingDiaryLazyQuery
>;
export type GetAccountTradingDiaryQueryResult = Apollo.QueryResult<
  GetAccountTradingDiaryQuery,
  GetAccountTradingDiaryQueryVariables
>;
export const GetAllUserCertificatesDocument = gql`
  query GetAllUserCertificates {
    getAllUserCertificates {
      ...Certificate
    }
  }
  ${CertificateFragmentDoc}
`;

/**
 * __useGetAllUserCertificatesQuery__
 *
 * To run a query within a React component, call `useGetAllUserCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserCertificatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUserCertificatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllUserCertificatesQuery,
    GetAllUserCertificatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllUserCertificatesQuery,
    GetAllUserCertificatesQueryVariables
  >(GetAllUserCertificatesDocument, options);
}
export function useGetAllUserCertificatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllUserCertificatesQuery,
    GetAllUserCertificatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllUserCertificatesQuery,
    GetAllUserCertificatesQueryVariables
  >(GetAllUserCertificatesDocument, options);
}
export type GetAllUserCertificatesQueryHookResult = ReturnType<
  typeof useGetAllUserCertificatesQuery
>;
export type GetAllUserCertificatesLazyQueryHookResult = ReturnType<
  typeof useGetAllUserCertificatesLazyQuery
>;
export type GetAllUserCertificatesQueryResult = Apollo.QueryResult<
  GetAllUserCertificatesQuery,
  GetAllUserCertificatesQueryVariables
>;
export const GetBrokerAccountLotsDocument = gql`
  query GetBrokerAccountLots($id: String!) {
    brokerAccountLotsRange(id: $id) {
      lowerBoundary
      upperBoundary
    }
  }
`;

/**
 * __useGetBrokerAccountLotsQuery__
 *
 * To run a query within a React component, call `useGetBrokerAccountLotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrokerAccountLotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrokerAccountLotsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBrokerAccountLotsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBrokerAccountLotsQuery,
    GetBrokerAccountLotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBrokerAccountLotsQuery,
    GetBrokerAccountLotsQueryVariables
  >(GetBrokerAccountLotsDocument, options);
}
export function useGetBrokerAccountLotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBrokerAccountLotsQuery,
    GetBrokerAccountLotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBrokerAccountLotsQuery,
    GetBrokerAccountLotsQueryVariables
  >(GetBrokerAccountLotsDocument, options);
}
export type GetBrokerAccountLotsQueryHookResult = ReturnType<
  typeof useGetBrokerAccountLotsQuery
>;
export type GetBrokerAccountLotsLazyQueryHookResult = ReturnType<
  typeof useGetBrokerAccountLotsLazyQuery
>;
export type GetBrokerAccountLotsQueryResult = Apollo.QueryResult<
  GetBrokerAccountLotsQuery,
  GetBrokerAccountLotsQueryVariables
>;
export const GetBrokerAccountStatisticsDocument = gql`
  query GetBrokerAccountStatistics($id: String!) {
    brokerAccountStatistics(id: $id) {
      ...BrokerAccountStatistics
    }
  }
  ${BrokerAccountStatisticsFragmentDoc}
`;

/**
 * __useGetBrokerAccountStatisticsQuery__
 *
 * To run a query within a React component, call `useGetBrokerAccountStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrokerAccountStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrokerAccountStatisticsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBrokerAccountStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBrokerAccountStatisticsQuery,
    GetBrokerAccountStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBrokerAccountStatisticsQuery,
    GetBrokerAccountStatisticsQueryVariables
  >(GetBrokerAccountStatisticsDocument, options);
}
export function useGetBrokerAccountStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBrokerAccountStatisticsQuery,
    GetBrokerAccountStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBrokerAccountStatisticsQuery,
    GetBrokerAccountStatisticsQueryVariables
  >(GetBrokerAccountStatisticsDocument, options);
}
export type GetBrokerAccountStatisticsQueryHookResult = ReturnType<
  typeof useGetBrokerAccountStatisticsQuery
>;
export type GetBrokerAccountStatisticsLazyQueryHookResult = ReturnType<
  typeof useGetBrokerAccountStatisticsLazyQuery
>;
export type GetBrokerAccountStatisticsQueryResult = Apollo.QueryResult<
  GetBrokerAccountStatisticsQuery,
  GetBrokerAccountStatisticsQueryVariables
>;
export const GetBrokerAccountDocument = gql`
  query GetBrokerAccount($id: String!) {
    brokerAccount(id: $id) {
      ...BrokerAccount
    }
  }
  ${BrokerAccountFragmentDoc}
`;

/**
 * __useGetBrokerAccountQuery__
 *
 * To run a query within a React component, call `useGetBrokerAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrokerAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrokerAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBrokerAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBrokerAccountQuery,
    GetBrokerAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrokerAccountQuery, GetBrokerAccountQueryVariables>(
    GetBrokerAccountDocument,
    options,
  );
}
export function useGetBrokerAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBrokerAccountQuery,
    GetBrokerAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBrokerAccountQuery,
    GetBrokerAccountQueryVariables
  >(GetBrokerAccountDocument, options);
}
export type GetBrokerAccountQueryHookResult = ReturnType<
  typeof useGetBrokerAccountQuery
>;
export type GetBrokerAccountLazyQueryHookResult = ReturnType<
  typeof useGetBrokerAccountLazyQuery
>;
export type GetBrokerAccountQueryResult = Apollo.QueryResult<
  GetBrokerAccountQuery,
  GetBrokerAccountQueryVariables
>;
export const GetBrokerAccountsDocument = gql`
  query GetBrokerAccounts {
    me {
      brokerAccounts {
        ...BrokerAccount
      }
    }
  }
  ${BrokerAccountFragmentDoc}
`;

/**
 * __useGetBrokerAccountsQuery__
 *
 * To run a query within a React component, call `useGetBrokerAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrokerAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrokerAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBrokerAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBrokerAccountsQuery,
    GetBrokerAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBrokerAccountsQuery,
    GetBrokerAccountsQueryVariables
  >(GetBrokerAccountsDocument, options);
}
export function useGetBrokerAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBrokerAccountsQuery,
    GetBrokerAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBrokerAccountsQuery,
    GetBrokerAccountsQueryVariables
  >(GetBrokerAccountsDocument, options);
}
export type GetBrokerAccountsQueryHookResult = ReturnType<
  typeof useGetBrokerAccountsQuery
>;
export type GetBrokerAccountsLazyQueryHookResult = ReturnType<
  typeof useGetBrokerAccountsLazyQuery
>;
export type GetBrokerAccountsQueryResult = Apollo.QueryResult<
  GetBrokerAccountsQuery,
  GetBrokerAccountsQueryVariables
>;
export const GetClosedPositionsDocument = gql`
  query GetClosedPositions($login: String) {
    closedPositions(login: $login) {
      ...AccountTradingPosition
    }
  }
  ${AccountTradingPositionFragmentDoc}
`;

/**
 * __useGetClosedPositionsQuery__
 *
 * To run a query within a React component, call `useGetClosedPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClosedPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClosedPositionsQuery({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useGetClosedPositionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClosedPositionsQuery,
    GetClosedPositionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClosedPositionsQuery,
    GetClosedPositionsQueryVariables
  >(GetClosedPositionsDocument, options);
}
export function useGetClosedPositionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClosedPositionsQuery,
    GetClosedPositionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClosedPositionsQuery,
    GetClosedPositionsQueryVariables
  >(GetClosedPositionsDocument, options);
}
export type GetClosedPositionsQueryHookResult = ReturnType<
  typeof useGetClosedPositionsQuery
>;
export type GetClosedPositionsLazyQueryHookResult = ReturnType<
  typeof useGetClosedPositionsLazyQuery
>;
export type GetClosedPositionsQueryResult = Apollo.QueryResult<
  GetClosedPositionsQuery,
  GetClosedPositionsQueryVariables
>;
export const GetForgottenPasswordTokenDocument = gql`
  query GetForgottenPasswordToken($id: String!) {
    forgottenPassword(id: $id) {
      status
    }
  }
`;

/**
 * __useGetForgottenPasswordTokenQuery__
 *
 * To run a query within a React component, call `useGetForgottenPasswordTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetForgottenPasswordTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetForgottenPasswordTokenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetForgottenPasswordTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetForgottenPasswordTokenQuery,
    GetForgottenPasswordTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetForgottenPasswordTokenQuery,
    GetForgottenPasswordTokenQueryVariables
  >(GetForgottenPasswordTokenDocument, options);
}
export function useGetForgottenPasswordTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetForgottenPasswordTokenQuery,
    GetForgottenPasswordTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetForgottenPasswordTokenQuery,
    GetForgottenPasswordTokenQueryVariables
  >(GetForgottenPasswordTokenDocument, options);
}
export type GetForgottenPasswordTokenQueryHookResult = ReturnType<
  typeof useGetForgottenPasswordTokenQuery
>;
export type GetForgottenPasswordTokenLazyQueryHookResult = ReturnType<
  typeof useGetForgottenPasswordTokenLazyQuery
>;
export type GetForgottenPasswordTokenQueryResult = Apollo.QueryResult<
  GetForgottenPasswordTokenQuery,
  GetForgottenPasswordTokenQueryVariables
>;
export const GetFundedAccountsDocument = gql`
  query GetFundedAccounts {
    fundedAccounts {
      ...FundedAccount
    }
  }
  ${FundedAccountFragmentDoc}
`;

/**
 * __useGetFundedAccountsQuery__
 *
 * To run a query within a React component, call `useGetFundedAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFundedAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFundedAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFundedAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFundedAccountsQuery,
    GetFundedAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFundedAccountsQuery,
    GetFundedAccountsQueryVariables
  >(GetFundedAccountsDocument, options);
}
export function useGetFundedAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFundedAccountsQuery,
    GetFundedAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFundedAccountsQuery,
    GetFundedAccountsQueryVariables
  >(GetFundedAccountsDocument, options);
}
export type GetFundedAccountsQueryHookResult = ReturnType<
  typeof useGetFundedAccountsQuery
>;
export type GetFundedAccountsLazyQueryHookResult = ReturnType<
  typeof useGetFundedAccountsLazyQuery
>;
export type GetFundedAccountsQueryResult = Apollo.QueryResult<
  GetFundedAccountsQuery,
  GetFundedAccountsQueryVariables
>;
export const GetOrderDocument = gql`
  query GetOrder($orderNumber: String!, $secureHash: String) {
    order(orderNumber: $orderNumber, secureHash: $secureHash) {
      ...OrderResponse
    }
  }
  ${OrderResponseFragmentDoc}
`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      orderNumber: // value for 'orderNumber'
 *      secureHash: // value for 'secureHash'
 *   },
 * });
 */
export function useGetOrderQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(
    GetOrderDocument,
    options,
  );
}
export function useGetOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderQuery,
    GetOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(
    GetOrderDocument,
    options,
  );
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<
  typeof useGetOrderLazyQuery
>;
export type GetOrderQueryResult = Apollo.QueryResult<
  GetOrderQuery,
  GetOrderQueryVariables
>;
export const GetOrdersDocument = gql`
  query GetOrders {
    orders {
      ...OrderResponse
    }
  }
  ${OrderResponseFragmentDoc}
`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrdersQuery,
    GetOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(
    GetOrdersDocument,
    options,
  );
}
export function useGetOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrdersQuery,
    GetOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(
    GetOrdersDocument,
    options,
  );
}
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<
  typeof useGetOrdersLazyQuery
>;
export type GetOrdersQueryResult = Apollo.QueryResult<
  GetOrdersQuery,
  GetOrdersQueryVariables
>;
export const GetWalletDocument = gql`
  query GetWallet {
    wallet {
      ...Wallet
    }
  }
  ${WalletFragmentDoc}
`;

/**
 * __useGetWalletQuery__
 *
 * To run a query within a React component, call `useGetWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWalletQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWalletQuery,
    GetWalletQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWalletQuery, GetWalletQueryVariables>(
    GetWalletDocument,
    options,
  );
}
export function useGetWalletLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWalletQuery,
    GetWalletQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWalletQuery, GetWalletQueryVariables>(
    GetWalletDocument,
    options,
  );
}
export type GetWalletQueryHookResult = ReturnType<typeof useGetWalletQuery>;
export type GetWalletLazyQueryHookResult = ReturnType<
  typeof useGetWalletLazyQuery
>;
export type GetWalletQueryResult = Apollo.QueryResult<
  GetWalletQuery,
  GetWalletQueryVariables
>;
export const GetViewerDocument = gql`
  query GetViewer {
    me {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useGetViewerQuery__
 *
 * To run a query within a React component, call `useGetViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetViewerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetViewerQuery,
    GetViewerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetViewerQuery, GetViewerQueryVariables>(
    GetViewerDocument,
    options,
  );
}
export function useGetViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetViewerQuery,
    GetViewerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetViewerQuery, GetViewerQueryVariables>(
    GetViewerDocument,
    options,
  );
}
export type GetViewerQueryHookResult = ReturnType<typeof useGetViewerQuery>;
export type GetViewerLazyQueryHookResult = ReturnType<
  typeof useGetViewerLazyQuery
>;
export type GetViewerQueryResult = Apollo.QueryResult<
  GetViewerQuery,
  GetViewerQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export enum AccountTradingPositionAction {
  Buy = 'BUY',
  Sell = 'SELL',
}

export enum AccountType {
  Challenge = 'CHALLENGE',
  Funded = 'FUNDED',
  SharpEdge = 'SHARP_EDGE',
  StablePath = 'STABLE_PATH',
  Trial = 'TRIAL',
}

export enum AddonType {
  IncreasedLeverage = 'INCREASED_LEVERAGE',
  IncreasedProfitSplit = 'INCREASED_PROFIT_SPLIT',
  WeeklyPayouts = 'WEEKLY_PAYOUTS',
}

export enum BrokerAccountIntentResponseStatus {
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Provisioned = 'PROVISIONED',
}

export enum BrokerAccountType {
  Demo = 'DEMO',
  Live = 'LIVE',
}

export enum CalendarRecordImpact {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  NonEconomic = 'NON_ECONOMIC',
  Unknown = 'UNKNOWN',
}

export enum CartStatus {
  Completed = 'COMPLETED',
  Created = 'CREATED',
}

export enum CertificateType {
  AffiliateWithdrawal = 'AFFILIATE_WITHDRAWAL',
  FundedAccountAchievement = 'FUNDED_ACCOUNT_ACHIEVEMENT',
  Phase1Achievement = 'PHASE1_ACHIEVEMENT',
  TotalWithdrawal = 'TOTAL_WITHDRAWAL',
  Withdrawal = 'WITHDRAWAL',
}

export enum ChallengeStatus {
  Evaluation = 'EVALUATION',
  Failed = 'FAILED',
  Passed = 'PASSED',
}

export type ChangePasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type InvitationInput = {
  email: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};

export enum InvitationStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
}

export enum KycStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Requested = 'REQUESTED',
  Verified = 'VERIFIED',
}

export enum MonevisAnalysisResultOutcome {
  Evaluating = 'EVALUATING',
  Fail = 'FAIL',
  Pass = 'PASS',
}

export enum NotificationTypeEnum {
  AcceptedInvitation = 'ACCEPTED_INVITATION',
}

export type OrderAddress = {
  city: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  street: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export enum OrderStatus {
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Provisioning = 'PROVISIONING',
}

export enum PaymentIntentStatus {
  Expired = 'EXPIRED',
  Paid = 'PAID',
  Pending = 'PENDING',
}

export enum PaymentMethod {
  Confirmo = 'CONFIRMO',
  Stripe = 'STRIPE',
}

export type PaymentMethodInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<WithdrawalPaymentMethod>;
  paymentMethodMetadata?: InputMaybe<Scalars['String']['input']>;
  targetAddress?: InputMaybe<Scalars['String']['input']>;
};

export enum PhoneNumberValidationResponseStatus {
  Invalid = 'INVALID',
  NonUnique = 'NON_UNIQUE',
  Valid = 'VALID',
}

export type RegistrationInput = {
  affiliateId?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  invitationCode?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  preferredLocale?: InputMaybe<Scalars['String']['input']>;
};

export type RegistrationInputForOrder = {
  affiliateId?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  invitationCode?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferredLocale?: InputMaybe<Scalars['String']['input']>;
};

export type RequestBrokerAccountInput = {
  currency: Scalars['String']['input'];
  initialBalance: Scalars['Int']['input'];
  leverage: Scalars['Int']['input'];
};

export enum SocialLoginProvider {
  Discord = 'DISCORD',
  Google = 'GOOGLE',
  Twitter = 'TWITTER',
}

export enum TransactionType {
  AffiliateAccount = 'AFFILIATE_ACCOUNT',
  TradingAccount = 'TRADING_ACCOUNT',
}

export type UpdateUserInput = {
  affiliateUsername?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export enum ValidationCodeResponseStatusEnum {
  AlreadyVerified = 'ALREADY_VERIFIED',
  Error = 'ERROR',
  Sent = 'SENT',
}

export enum ValidationCodeTypeEnum {
  Email = 'EMAIL',
  Phone = 'PHONE',
}

export enum WithdrawalPaymentMethod {
  DirectCrypto = 'DIRECT_CRYPTO',
  Rise = 'RISE',
}
