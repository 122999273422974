import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import csTranslation from '../locales/cs.json';
import enTranslation from '../locales/en.json';
import skTranslation from '../locales/sk.json';

const resources = {
  en: {
    translation: enTranslation,
  },
  cs: {
    translation: csTranslation,
  },
  sk: {
    translation: skTranslation,
  },
};
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: import.meta.env.VITE_INTL_KEY_MODE === 'true' ? {} : resources,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV != 'production',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: [
        'localStorage',
        'cookie',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;
