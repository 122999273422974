import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/')({
  beforeLoad: async ({ context, location }) => {
    if (context.auth.isAuthenticated && !context.auth.loading) {
      const searchParams = new URLSearchParams(location.search);
      const redirectPath = searchParams.get('redirect');
      throw redirect({
        to: redirectPath ?? '/dashboard/home',
      });
    } else if (!context.auth.isAuthenticated && !context.auth.loading) {
      throw redirect({
        to: '/auth/login',
      });
    }
  },
});
