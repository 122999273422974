import { KYCFullPage } from '@/components/(authenticated)/kyc';
import { PageWrapper } from '@/components/base/page-wrapper';
import { ScreenContainer } from '@/components/base/screen-container';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/(authenticated)/dashboard/_layout/identity',
)({
  component: () => (
    <PageWrapper title="KYC - Monevis">
      <KYCPage />
    </PageWrapper>
  ),
});

function KYCPage(): JSX.Element {
  // const { t } = useTranslation();
  // usePageLayout({ title: t('payoutPage.title') });

  return (
    <ScreenContainer className="justify-between">
      <KYCFullPage />
    </ScreenContainer>
  );
}

export default KYCPage;
