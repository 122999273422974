import Section from '@/components/base/section';
import { type ReactNode } from 'react';

export default function WidgetWithImage({
  img,
  header,
  subheader,
  children,
}: {
  img: string;
  header: ReactNode;
  subheader: ReactNode;
  children: ReactNode;
}): JSX.Element {
  return (
    <Section variant="secondary" className="md:mt-10">
      <div className="flex flex-col gap-6 md:max-w-96">
        <div className="flex flex-col gap-3">
          <h1 className="text-2xl font-bold dark:text-white">{header}</h1>
          {subheader && (
            <div className="max-w-sm text-sm text-default-gray-500 dark:text-default-gray-300">
              {subheader}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-5 dark:text-white md:flex-row">
          {children}
        </div>
      </div>
      <div className="flex justify-center">
        <img
          src={img}
          className="z-40 h-60 transform md:absolute md:bottom-0 md:right-0 md:top-0 md:h-64 md:translate-y-[-15%] xl:right-20 xl:h-72"
          alt="phones"
        />
      </div>
    </Section>
  );
}
