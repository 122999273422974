import { Divider } from '@/components/base/divider';
import { NavLink } from '@/components/base/nav-link';
import { SocialLoginButtons } from '@/components/base/social-buttons';
import { EventName, useAnalytics } from '@/context/analytics-context';
import { CredentialsContext } from '@/context/credentials-context';
import { useRegisterUserMutation } from '@graphql/index';
import { Link } from '@tanstack/react-router';
import { enqueueSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { FormLayout } from '../auth-form-layout';
import { EmailAuthButton } from '../email-auth-button';
import { RegistrationForm } from './registration-form';

enum FormTypeEnum {
  SocialButtons = 'SocialButtons',
  SignUp = 'SignUp',
}

interface FormData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  countryCode: string;
}

export function Registration(): JSX.Element {
  const { t } = useTranslation();
  const [register, { loading }] = useRegisterUserMutation();
  const { track, identify } = useAnalytics();
  const [formType, setFormType] = useState<FormTypeEnum>(
    FormTypeEnum.SocialButtons,
  );
  const [cookie] = useCookies(['_fprom_tid']);

  const { setToken } = useContext(CredentialsContext);

  function openRegisterForm(): void {
    setFormType(FormTypeEnum.SignUp);
  }
  function closeRegisterForm(): void {
    setFormType(FormTypeEnum.SocialButtons);
  }

  async function handleSubmit(values: FormData): Promise<void> {
    try {
      const { data } = await register({
        variables: {
          user: {
            email: values.email,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            countryCode: values.countryCode,
            affiliateId: cookie._fprom_tid ? String(cookie._fprom_tid) : null,
          },
        },
      });

      if (data?.register?.token) {
        setToken(data.register.token);
        track(EventName.Lead, {
          country: String(data?.register.countryCode),
          email: String(data?.register.email),
        });
        identify(data.register.id, {
          first_name: data.register.firstName,
          last_name: data.register.lastName,
          email: data.register.email,
        });
      }
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar({
          message:
            e.message === 'USER_ALREADY_EXISTS'
              ? t('registration.userAlreadyExists')
              : t('login.invalidCredentials'),
          variant: 'error',
        });
      }
      // console.error(error);
    }
  }

  return (
    <div className="flex h-full w-full justify-center overflow-y-scroll rounded-none bg-default-gray-950 p-5 text-white shadow-xl sm:p-10 lg:rounded-tl-[20px]">
      <div className="relative mx-auto h-full w-full">
        <FormLayout
          arrowBackText={t('registration.arrowBackText')}
          subtitleText={
            formType === FormTypeEnum.SignUp
              ? t('registration.signUpWithEmail')
              : t('registration.signUp')
          }
          showGoBackOption={formType === FormTypeEnum.SignUp}
          onClickBack={closeRegisterForm}
          link={
            <>
              <span className="text-base">
                {t('registration.alreadyHaveAccount')}{' '}
              </span>
              <NavLink asChild>
                <Link
                  to="/auth/login"
                  className="text-base font-bold text-white underline underline-offset-4"
                >
                  {t('registration.signIn')}
                </Link>
              </NavLink>
            </>
          }
        >
          {formType === FormTypeEnum.SignUp && (
            <RegistrationForm onSubmit={handleSubmit} loading={loading} />
          )}
          {formType === FormTypeEnum.SocialButtons && (
            <div>
              <SocialLoginButtons
                googleButtonText={t('registration.signUpWithGoogle')}
              >
                <Divider>{t('registration.orSignUpWithEmail')}</Divider>
                <EmailAuthButton
                  onClick={openRegisterForm}
                  data-testid="email-auth-button"
                />
              </SocialLoginButtons>
            </div>
          )}
        </FormLayout>
      </div>
    </div>
  );
}
